import React from "react";
import styled from "styled-components";

import { useAppState } from "lib/hooks/useAppState";
import { useMediaQuery } from "react-responsive";
import useTranslation from "lib/hooks/useTranslation";

const Header = ({ typ = "standard" }) => {
  const { state } = useAppState();
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const { language } = useTranslation();
  const folder = isDesktop ? "desktop/" : "mobile/";

  const getImageSource = () => {
    let source = "";

    if (isDesktop) {
      if (typ === "standard") {
        source = `/videos/Header_Desktop_1920x1080_6s.mp4`;
      }
      if (typ === "gewonnen") {
        source = `/images/header/DesktopHeader_Win.jpg`;
      }
      if (typ === "verloren") {
        source = `/images/header/DesktopHeader_Niete_${language.toUpperCase()}.jpg`;
      }
    } else {
      if (typ === "standard") {
        source = `/videos/Header_Mobile_1080x1374_6s.mp4`;
      }
      if (typ === "gewonnen") {
        source = `/images/header/MobileHeader_Win.jpg`;
      }
      if (typ === "verloren") {
        source = `/images/header/MobileHeader_Niete_${language.toUpperCase()}.jpg`;
      }
    }

    return source;
  };

  return (
    <>
      <div>
        <video autoPlay muted playsInline loop key={getImageSource()}>
          <source src={getImageSource()} type="video/mp4"></source>
          Your browser does not support the video tag.
        </video>
      </div>
      {/*       <Overlay
        src={getImageSource()}
        name={`week_${state.week}`}
        folder={folder}
        alt=""
      /> */}
    </>
  );
};

const Overlay = styled.img`
  max-width: 100%;
  display: block;
  margin: 0 auto;
  /* padding-top: 71px; */
  padding-bottom: 32px;
`;

export default Header;
