import useTranslation from "lib/hooks/useTranslation";
import styled from "styled-components";
import { StyledLead } from "styles/typo";

const CookieBanner = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Text
        dangerouslySetInnerHTML={{
          __html: t("cookie_text"),
        }}
      ></Text>
      <Button onClick={onClick}>{t("cookie_button")}</Button>
    </Container>
  );
};

// helper
const Container = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 1em 1.8em;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.5em;
  z-index: 9999;
  background-color: #696969;

  @media (min-width: 600px) {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }
`;

const Text = styled(StyledLead)`
  color: white;
  a {
    color: white;
  }
`;

const Button = styled.button`
  color: #444;
  border-color: transparent;
  background-color: rgb(255, 255, 255);
  display: block;
  padding: 0.4em 0.8em;
  font-style: normal;
  font-weight: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  border-width: 2px;
  border-style: solid;
  text-align: center;
  white-space: nowrap;
  border-radius: 5px;
  height: 37.5px;
  cursor: pointer;
  width: 100%;
  margin-top: 16px;
  padding-bottom: 16px;

  @media (min-width: 600px) {
    width: auto;
  }
`;

export default CookieBanner;
