// Formular State
// Alle User Daten und alle Antworten
import React, { useReducer, useContext } from "react";

export const ACTIONS = {
  SET_DATA: "SET_DATA",
};

const INITIAL_STATE = {
  land: "CH",
  gewonnen: true,
  email: "",
  anrede: "",
  vorname: "",
  nachname: "",
  strasse: "",
  nummer: "",
  plz: "",
  ort: "",
  tag: "",
  monat: "",
  jahr: "",
  ch: false,
  tb: false,
  F1_A: false,
  F1_B: false,
  F1_C: false,
  F1_D: false,
  F1_E: false,
  F1_F: false,
  F2_A: false,
  F2_B: false,
  F2_C: false,
  F2_D: false,
  F3_A: false,
  F3_B: false,
  F3_C: false,
  F3_D: false,
  F3_E: false,
  /* F3_F: false, */
  /*   F3_A: 50,
  F3_A_SEHR_WICHTIG: false,
  F3_A_WICHTIG: false,
  F3_A_TOUCHED: false,
  F3_B: 50,
  F3_B_SEHR_WICHTIG: false,
  F3_B_WICHTIG: false,
  F3_B_TOUCHED: false,
  F3_C: 50,
  F3_C_SEHR_WICHTIG: false,
  F3_C_WICHTIG: false,
  F3_C_TOUCHED: false, */
  F4_A: 50,
  F4_A_SEHR_WAHRSCHEINLICH: false,
  F4_A_WAHRSCHEINLICH: false,
  F4_A_TOUCHED: false,
  wohnhaft: false,
  teilnahmebedingungen: false,
  antworten_1: [
    { kennung: "F1_A", nummer: 1 },
    { kennung: "F1_B", nummer: 2 },
    { kennung: "F1_C", nummer: 3 },
    { kennung: "F1_D", nummer: 4 },
    { kennung: "F1_E", nummer: 5 },
  ]
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value),
  antworten_2: [
    { kennung: "F2_A", nummer: 1 },
    { kennung: "F2_B", nummer: 2 },
    { kennung: "F2_C", nummer: 3 },
    { kennung: "F2_D", nummer: 4 },
  ],
  antworten_3: [
    { kennung: "F3_A", nummer: 1 },
    { kennung: "F3_B", nummer: 2 },
    { kennung: "F3_C", nummer: 3 },
    { kennung: "F3_D", nummer: 4 },
    { kennung: "F3_E", nummer: 5 },
  ]
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value),
};

const reducer = function (state, action) {
  switch (action.type) {
    case ACTIONS.SET_DATA:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };

    case ACTIONS.RESET:
      return {
        land: "CH",
        gewonnen: true,
        email: "",
        anrede: "",
        vorname: "",
        nachname: "",
        strasse: "",
        nummer: "",
        plz: "",
        ort: "",
        tag: "",
        monat: "",
        jahr: "",
        ch: false,
        tb: false,
        F1_A: false,
        F1_B: false,
        F1_C: false,
        F1_D: false,
        F1_E: false,
        F1_F: false,
        F2_A: false,
        F2_B: false,
        F2_C: false,
        F2_D: false,
        F3_A: false,
        F3_B: false,
        F3_C: false,
        F3_D: false,
        F3_E: false,

        /*         F3_A: 50,
        F3_A_SEHR_WICHTIG: false,
        F3_A_WICHTIG: false,
        F3_A_TOUCHED: false,
        F3_B: 50,
        F3_B_SEHR_WICHTIG: false,
        F3_B_WICHTIG: false,
        F3_B_TOUCHED: false,
        F3_C: 50,
        F3_C_SEHR_WICHTIG: false,
        F3_C_WICHTIG: false,
        F3_C_TOUCHED: false, */
        F4_A: 50,
        F4_A_SEHR_WAHRSCHEINLICH: false,
        F4_A_WAHRSCHEINLICH: false,
        F4_A_TOUCHED: false,
        wohnhaft: false,
        teilnahmebedingungen: false,
        antworten_1: [
          { kennung: "F1_A", nummer: 1 },
          { kennung: "F1_B", nummer: 2 },
          { kennung: "F1_C", nummer: 3 },
          { kennung: "F1_D", nummer: 4 },
          { kennung: "F1_E", nummer: 5 },
        ]
          .map((value) => ({ value, sort: Math.random() }))
          .sort((a, b) => a.sort - b.sort)
          .map(({ value }) => value),
        antworten_2: [
          { kennung: "F2_A", nummer: 1 },
          { kennung: "F2_B", nummer: 2 },
          { kennung: "F2_C", nummer: 3 },
          { kennung: "F2_D", nummer: 4 },
        ],
        antworten_3: [
          { kennung: "F3_A", nummer: 1 },
          { kennung: "F3_B", nummer: 2 },
          { kennung: "F3_C", nummer: 3 },
          { kennung: "F3_D", nummer: 4 },
          { kennung: "F3_E", nummer: 5 },
        ]
          .map((value) => ({ value, sort: Math.random() }))
          .sort((a, b) => a.sort - b.sort)
          .map(({ value }) => value),
      };
    default:
      return {
        ...state,
      };
  }
};

const FormularContext = React.createContext();

export const FormularStateProvider = ({ children }) => {
  const [formState, formDispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <FormularContext.Provider value={{ formState, formDispatch }}>
      {children}
    </FormularContext.Provider>
  );
};

export const useFormularState = () => {
  return useContext(FormularContext);
};
