import useTranslation from "lib/hooks/useTranslation";
import React from "react";

const ProduktTeaser = () => {



  const { t, language } = useTranslation();

    const handleClick = () => {
      window.open(
        language === "de"
          ? "https://www.emmi-energy-milk.ch/che/de/Produkte/DoubleZero"
          : "https://www.emmi-energy-milk.ch/che/fr/Produits/DoubleZero",
        "_blank"
      );
    };

  return (
    <>
      {/* Desktop Anzeige */}
      <div className="bg-[#FFED00] hidden md:block mx-4">
        <div className="grid grid-flow-col grid-cols-[55%_45%]">
          <div className="flex flex-col justify-center">
            <div
              dangerouslySetInnerHTML={{
                __html: t("produkte_teaser_text"),
              }}
              className="text-black text-3xl md:text-[26px] md:leading-[36px] font-extrabold italic mt-8 px-8 md:px-10"
            ></div>
            <div className="px-8 mt-6 pb-8 bg-[#FFED00]">
              <button
                className="italic font-extrabold uppercase text-lg text-[#fff] bg-[#000] px-8 py-4 "
                onClick={handleClick}
              >
                {t("startseite_btn_probieren")}
              </button>
            </div>
          </div>
          <div>
            <img
              className="leading-none block align-middle"
              src={`/images/doublezero/KeyVisual_Doublezero_Microsite 1.png`}
              alt=""
            ></img>
          </div>
        </div>
      </div>
      {/* Mobile Anzeige */}
      <div className="md:w-4/12 bg-[#FFED00] mx-8 md:hidden">
        <img
          className="leading-none block align-middle"
          src={`/images/doublezero/KeyVisual_Doublezero_Microsite 1.png`}
          alt=""
        ></img>
        <div
          dangerouslySetInnerHTML={{
            __html: t("produkte_teaser_text"),
          }}
          className="text-black text-3xl md:text-[26px] md:leading-[36px] md:text-center font-extrabold italic mt-8 px-8 md:px-10"
        ></div>
        <div className="px-8 mt-6 pb-8 bg-[#FFED00]">
          <button
            className="italic font-extrabold uppercase text-lg text-[#fff] bg-[#000] px-8 py-4"
            onClick={handleClick}
          >
            {t("startseite_btn_probieren")}
          </button>
        </div>
      </div>
    </>
  );
};

export default ProduktTeaser;
