import useTranslation from "lib/hooks/useTranslation";
import React, { useEffect } from "react";
import {
  optionItemsTage,
  optionItemsMonate,
  optionItemsJahre,
  optionItemsAnrede_de,
  optionItemsAnrede_fr,
} from "helper/optionsItems";
import {
  ACTIONS as FORMACTIONS,
  useFormularState,
} from "lib/hooks/useFormularState";
import { jsUcfirst } from "lib/library";

const Frage_5 = ({ setFehler, error, setError }) => {
  const { t, language } = useTranslation();

  const handleChange = (event) => {
    setError("");
    setFehler("");
    if (event.target.name !== "email") {
      event.target.value = jsUcfirst(event.target.value.toLowerCase());
    }

    if (event.target.name === "vorname" || event.target.name === "nachname") {
      event.target.value = event.target.value.match(
        /[\p{Letter}\p{Mark}\s-]+/gu
      );
      // Grossbuchstabe nach Bindestrich!
      event.target.value = event.target.value.replace(
        /(^|[\s-])\S/g,
        function (match) {
          return match.toUpperCase();
        }
      );
    }

    formDispatch({
      type: FORMACTIONS.SET_DATA,
      payload: {
        key: event.target.name,
        value: event.target.value,
      },
    });
  };
  const onChange = (event) => {
    setFehler("");
    setError("");
    formDispatch({
      type: FORMACTIONS.SET_DATA,
      payload: {
        key: event.target.name,
        value: event.target.checked,
      },
    });
  };
  const { formDispatch, formState } = useFormularState();

  return (
    <div>
      <div className="text-lg mt-8 px-8  md:text-[26px] md:leading-[36px]">
        {t("formular_titel")}
      </div>
      <div className="px-8 text-left">
        <select
          value={formState.anrede}
          onChange={handleChange}
          name="anrede"
          className={`${
            error === "anrede" ? "bg-[#FFED00]" : "bg-white"
          } mx-auto md:mx-0 block form-input px-4 py-6 rounded-md w-full md:w-8/12 text-left mt-4 text-[#000] text-base font-normal border-none focus:border-transparent focus:ring-0`}
        >
          <option selected>{t("formular_anrede")}</option>
          {language === "de"
            ? optionItemsAnrede_de.map((item) => {
                return (
                  <option key={item.key} value={item.value}>
                    {item.text}
                  </option>
                );
              })
            : optionItemsAnrede_fr.map((item) => {
                return (
                  <option key={item.key} value={item.value}>
                    {item.text}
                  </option>
                );
              })}
        </select>

        <input
          value={formState.vorname}
          type="text"
          onChange={handleChange}
          name="vorname"
          placeholder={t("formular_vorname")}
          className={`${
            error === "vorname" ? "bg-[#FFED00]" : "bg-white"
          } mx-auto md:mx-0 block form-input px-4 py-6 rounded-md w-full md:w-8/12 text-left mt-4 text-[#000] text-base font-normal border-none focus:border-transparent focus:ring-0`}
        ></input>

        <input
          value={formState.nachname}
          type="text"
          onChange={handleChange}
          name="nachname"
          placeholder={t("formular_nachname")}
          className={`${
            error === "nachname" ? "bg-[#FFED00]" : "bg-white"
          } mx-auto md:mx-0 block form-input px-4 py-6 rounded-md w-full md:w-8/12 text-left mt-4 text-[#000] text-base font-normal border-none focus:border-transparent focus:ring-0`}
        ></input>

        <div className="flex md:w-8/12">
          <select
            value={formState.tag}
            onChange={handleChange}
            name="tag"
            className={`${
              error === "tag" ? "bg-[#FFED00]" : "bg-white"
            } mx-auto block form-input px-4 py-6 rounded-md w-full md:w-8/12 text-left mt-4 text-[#000] text-base font-normal border-none focus:border-transparent focus:ring-0`}
          >
            {formState.tag === "" && <option>{t("formular_tag")}</option>}
            {optionItemsTage.map((item) => {
              return (
                <option key={item.key} value={item.value}>
                  {item.text}
                </option>
              );
            })}
          </select>
          <select
            value={formState.monat}
            onChange={handleChange}
            name="monat"
            className={`${
              error === "monat" ? "bg-[#FFED00]" : "bg-white"
            } mx-auto block form-input px-4 py-6 rounded-md w-full md:w-8/12 text-left mt-4 text-[#000] text-base font-normal border-none focus:border-transparent focus:ring-0 ml-2`}
          >
            {formState.monat === "" && <option>{t("formular_monat")}</option>}
            {optionItemsMonate.map((item) => {
              return (
                <option key={item.key} value={item.value}>
                  {item.text}
                </option>
              );
            })}
          </select>
          <select
            value={formState.jahr}
            onChange={handleChange}
            name="jahr"
            className={`${
              error === "jahr" ? "bg-[#FFED00]" : "bg-white"
            } mx-auto block form-input px-4 py-6 rounded-md w-full md:w-8/12 text-left mt-4 text-[#000] text-base font-normal border-none focus:border-transparent focus:ring-0 ml-2`}
          >
            {formState.jahr === "" && <option>{t("formular_jahr")}</option>}
            {optionItemsJahre.map((item) => {
              return (
                <option key={item.key} value={item.value}>
                  {item.text}
                </option>
              );
            })}
          </select>
        </div>
      </div>

      <div className="px-8 md:w-8/12">
        {/* Antwort 1 */}
        <div className="flex items-center mt-6">
          <input
            checked={formState.wohnhaft}
            name="wohnhaft"
            onChange={onChange}
            id="check_1"
            type="checkbox"
            className="text-black bg-black w-6 h-6 border border-white rounded checked:border-white checked:hover:border-white checked:hover:bg-black focus:border-transparent focus:ring-0 "
          />
          <label
            htmlFor="check_1"
            className={`${
              error === "wohnhaft" ? "bg-[#FFED00] text-black" : "bg-black"
            } ml-4`}
          >
            {t("formular_wohnhaft")}
          </label>
        </div>
        {/* Antwort 2 */}
        <div className="flex items-center mt-6">
          <input
            checked={formState.teilnahmebedingungen}
            name="teilnahmebedingungen"
            onChange={onChange}
            id="check_2"
            type="checkbox"
            className="text-black bg-black w-6 h-6 border border-white rounded checked:border-white checked:hover:border-white checked:hover:bg-black focus:border-transparent focus:ring-0 "
          />
          <label
            htmlFor="check_2"
            className={`${
              error === "teilnahmebedingungen"
                ? "bg-[#FFED00] text-black"
                : "bg-black"
            } ml-4`}
          >
            {t("formular_tbs")}{" "}
            <span
              className="cursor-pointer underline"
              onClick={() => {
                window.open(t("footer_tb_link"));
              }}
            >
              {t("formular_tbs_1")}
            </span>
          </label>
        </div>
      </div>
      <div className="text-lg mt-8 px-8 md:w-8/12">
        {t("startseite_pflichtfeld")}
      </div>
    </div>
  );
};

export default Frage_5;
