import styled from "styled-components";

export const YSpacer = styled.div`
  height: ${(props) => (props.mb ? props.mb + "px" : "0px")};
  @media (min-width: ${(props) => props.theme.breakPoints.sm}) {
    height: ${(props) => props.sm && props.sm + "px"};
  }
  @media (min-width: ${(props) => props.theme.breakPoints.md}) {
    height: ${(props) => props.md && props.md + "px"};
  }
  @media (min-width: ${(props) => props.theme.breakPoints.lg}) {
    height: ${(props) => props.lg && props.lg + "px"};
  }
  @media (min-width: ${(props) => props.theme.breakPoints.xl}) {
    height: ${(props) => props.xl && props.xl + "px"};
  }
  @media (min-width: ${(props) => props.theme.breakPoints.xxl}) {
    height: ${(props) => props.xxl && props.xxl + "px"};
  }
`;

export const XSpacer = styled.div`
  width: ${(props) => (props.mb ? props.mb + "px" : "0px")};
  @media (min-width: ${(props) => props.theme.breakPoints.sm}) {
    width: ${(props) => props.sm && props.sm + "px"};
  }
  @media (min-width: ${(props) => props.theme.breakPoints.md}) {
    width: ${(props) => props.md && props.md + "px"};
  }
  @media (min-width: ${(props) => props.theme.breakPoints.lg}) {
    width: ${(props) => props.lg && props.lg + "px"};
  }
  @media (min-width: ${(props) => props.theme.breakPoints.xl}) {
    width: ${(props) => props.xl && props.xl + "px"};
  }
  @media (min-width: ${(props) => props.theme.breakPoints.xxl}) {
    width: ${(props) => props.xxl && props.xxl + "px"};
  }
`;
