import React from "react";
import useTranslation from "lib/hooks/useTranslation";
import {
  ACTIONS as FORMACTIONS,
  useFormularState,
} from "lib/hooks/useFormularState";

const Frage_1 = ({ setFehler }) => {
  const { t } = useTranslation();
  const { formDispatch, formState } = useFormularState();

  const handleChange = (event) => {
    setFehler("");

    formDispatch({
      type: FORMACTIONS.SET_DATA,
      payload: {
        key: "F1_A_TOUCHED",
        value: true,
      },
    });

    console.log(event.target.value);

    formDispatch({
      type: FORMACTIONS.SET_DATA,
      payload: {
        key: event.target.name,
        value: parseInt(event.target.value),
      },
    });
  };

  return (
    <div>
      <div className="px-8 text-lg leading-7 font-extrabold italic md:text-[26px] md:leading-[36px]">
        {t("frage_1")}
      </div>
      <div
        dangerouslySetInnerHTML={{ __html: t("frage_1_wahrscheinlich") }}
        className="px-8 mt-2"
      ></div>
      <div className="px-8 mt-7">
        <input
          name="F1_A"
          style={{ backgroundSize: formState.F1_A + "% 100%" }}
          className="w-full h-4"
          type="range"
          value={formState.F1_A}
          min="0"
          max="100"
          id="range"
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default Frage_1;
