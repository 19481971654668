import React, { useEffect, useState } from "react";
import gluecksrad from "./Gluecksrad.png";
import rand from "./Rand_mit_anzeige.png";
import esp from "./ESP.png";
import mac from "./MAC.png";
import cap from "./CAP.png";
import styled from "styled-components";
import { YSpacer } from "lib/responsive-spacer";
import { motion, useAnimation } from "framer-motion";
import { useAppState, ACTIONS } from "lib/hooks/useAppState";
import { PRODUKTE } from "lib/constants";

const Rad = () => {
  const controls = useAnimation();
  const { dispatch, state } = useAppState();
  let produkt = null;
  const wertRotation = state.isSofortpreis ? 2880 : 2910;
  const [isCompleted, setIsCompleted] = useState(false);

  const onComplete = () => {
    /*  document.body.style.overflow = "unset"; */
    window.scrollTo({ top: 0, behavior: "smooth" });
    setIsCompleted(true);
    dispatch({
      type: state.isSofortpreis ? ACTIONS.SET_GEWONNEN : ACTIONS.SET_VERLOREN,
    });
    dispatch({
      type: ACTIONS.STOP_ANIMATION,
    });
  };

  switch (state.produkt_url) {
    case PRODUKTE.CAP:
      produkt = cap;
      break;
    case PRODUKTE.ESP:
      produkt = esp;
      break;
    case PRODUKTE.MAC:
      produkt = mac;
      break;
    default:
      produkt = cap;
  }

  useEffect(() => {
    if (state.startAnimation) {
      controls.start({
        rotate: wertRotation,
        transition: { duration: 6 },
      });
    }
  }, [controls, state.startAnimation, wertRotation]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  /*   useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "unset");
  }, []); */

  return (
    <Container>
      <YSpacer mb={120}></YSpacer>
      <WheelContainer isCompleted={isCompleted}>
        <Bild src={rand} alt="" />
        <AbsContainer zindex={2000}>
          <motion.div animate={controls}>
            <Bild src={produkt} alt="" />
          </motion.div>
        </AbsContainer>
        <AbsContainer zindex={200}>
          <Bild src={rand} alt=""></Bild>
        </AbsContainer>

        <AbsContainer zindex={100}>
          <motion.div onAnimationComplete={onComplete} animate={controls}>
            <Bild src={gluecksrad} alt="" />
          </motion.div>
        </AbsContainer>
      </WheelContainer>
    </Container>
  );
};

const WheelContainer = styled.div`
  position: relative;
  max-width: 120%;
  display: inline-block;
  height: ${(props) => (props.isCompleted ? "auto" : "100vh")};
  @media (min-width: ${(props) => props.theme.breakPoints.md}) {
    max-width: 600px;
  }
`;

const Bild = styled.img`
  max-width: 120%;
  display: block;
  margin-left: -10%;
  @media (min-width: 640px) {
    margin-left: 0;
    max-width: 100%;
    margin-left: 0;
  }
`;

const AbsContainer = styled.div`
  position: absolute;
  z-index: ${(props) => props.zindex};
  top: 0;
`;

const Container = styled.div`
  overflow: hidden;
  background-color: #fae000;
  text-align: center;
`;

export default Rad;
