// Die RegEx Pattern für die Validierung des Formulars
export const PATTERN_EMAIL = new RegExp(
  // eslint-disable-next-line
  /* /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ */
  /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/
);

/* export const PATTERN_TELEFON = new RegExp("^\\+(?:[0-9] ?){6,28}[0-9]$"); */
export const PATTERN_TELEFON = new RegExp("^\\+[1-9]{1}[0-9]{6,28}$");

export const PATTERN_PLZ = new RegExp(/^[0-9]{4}$/);

export const API_URL = {
  URL_LOCALE: "http://eem-new-year-2024/",
  URL_REMOTE: "https://energymilk.ch/php/",
  /* URL_REMOTE: "https://preview.win-michel.ch/php/", */
};

export const SCREENS = {
  STARTSEITE: "STARTSEITE",
  RAD: "RAD",
  RAILTOUR: "RAILTOUR",
  FORMULAR: "FORMULAR",
  GEWINN: "GEWINN",
  DANKE: "DANKE",
  SCHADE: "SCHADE",
};

export const PRODUKTE = {
  CAP: "CAP",
  ESP: "ESP",
  MAC: "MAC",
};

export const PREISE = {
  FITNESSABO: "fitnessabo-1000",
  GARMIN: "garmin-venu",
  OCHSNER: "ochsner",
  YOGAMATTE: "yogamatte",
  EXERBELL: "exerbell",
  FLASCHE: "flasche",
  MIKROFASERTUCH: "microfasertuch",
  TASCHE: "tasche",
};

export const CARDS = {
  YOGURT: "yogurt",
  PROTEINBEDARF: "proteinbedarf",
};

export const PLZ_LIE = [
  9485, 9486, 9487, 9488, 9489, 9490, 9491, 9492, 9493, 9494, 9495, 9496, 9497,
  9498,
];

export const PLZ_CH = [
  1000, 1001, 1002, 1003, 1004, 1005, 1006, 1007, 1008, 1009, 1010, 1011, 1012,
  1014, 1015, 1018, 1019, 1020, 1022, 1023, 1024, 1025, 1026, 1027, 1028, 1029,
  1030, 1031, 1032, 1033, 1034, 1035, 1036, 1037, 1038, 1039, 1040, 1041, 1042,
  1043, 1044, 1045, 1046, 1047, 1052, 1053, 1054, 1055, 1058, 1059, 1061, 1062,
  1063, 1066, 1068, 1070, 1071, 1072, 1073, 1076, 1077, 1078, 1080, 1081, 1082,
  1083, 1084, 1085, 1088, 1090, 1091, 1092, 1093, 1094, 1095, 1096, 1097, 1098,
  1110, 1112, 1113, 1114, 1115, 1116, 1117, 1121, 1122, 1123, 1124, 1125, 1126,
  1127, 1128, 1131, 1132, 1134, 1135, 1136, 1141, 1142, 1143, 1144, 1145, 1146,
  1147, 1148, 1149, 1162, 1163, 1164, 1165, 1166, 1167, 1168, 1169, 1170, 1172,
  1173, 1174, 1175, 1176, 1180, 1182, 1183, 1184, 1185, 1186, 1187, 1188, 1189,
  1195, 1196, 1197, 1200, 1201, 1202, 1203, 1204, 1205, 1206, 1207, 1208, 1209,
  1211, 1212, 1213, 1214, 1215, 1216, 1217, 1218, 1219, 1220, 1222, 1223, 1224,
  1225, 1226, 1227, 1228, 1231, 1232, 1233, 1234, 1236, 1237, 1239, 1240, 1241,
  1242, 1243, 1244, 1245, 1246, 1247, 1248, 1251, 1252, 1253, 1254, 1255, 1256,
  1257, 1258, 1260, 1261, 1262, 1263, 1264, 1265, 1266, 1267, 1268, 1269, 1270,
  1271, 1272, 1273, 1274, 1275, 1276, 1277, 1278, 1279, 1281, 1283, 1284, 1285,
  1286, 1287, 1288, 1290, 1291, 1292, 1293, 1294, 1295, 1296, 1297, 1298, 1299,
  1300, 1302, 1303, 1304, 1305, 1306, 1307, 1308, 1310, 1311, 1312, 1313, 1315,
  1316, 1317, 1318, 1320, 1321, 1322, 1323, 1324, 1325, 1326, 1329, 1330, 1337,
  1338, 1341, 1342, 1343, 1344, 1345, 1346, 1347, 1348, 1350, 1352, 1353, 1354,
  1355, 1356, 1357, 1358, 1372, 1373, 1374, 1375, 1376, 1377, 1400, 1401, 1404,
  1405, 1406, 1407, 1408, 1409, 1410, 1412, 1413, 1415, 1416, 1417, 1418, 1420,
  1421, 1422, 1423, 1424, 1425, 1426, 1427, 1428, 1429, 1430, 1431, 1432, 1433,
  1434, 1435, 1436, 1437, 1438, 1439, 1440, 1441, 1442, 1443, 1445, 1446, 1450,
  1452, 1453, 1454, 1462, 1463, 1464, 1468, 1470, 1473, 1474, 1475, 1482, 1483,
  1484, 1485, 1486, 1489, 1509, 1510, 1512, 1513, 1514, 1515, 1521, 1522, 1523,
  1524, 1525, 1526, 1527, 1528, 1529, 1530, 1532, 1533, 1534, 1535, 1536, 1537,
  1538, 1541, 1542, 1543, 1544, 1545, 1551, 1552, 1553, 1554, 1555, 1562, 1563,
  1564, 1565, 1566, 1567, 1568, 1580, 1583, 1584, 1585, 1586, 1587, 1588, 1589,
  1595, 1607, 1608, 1609, 1610, 1611, 1612, 1613, 1614, 1615, 1616, 1617, 1618,
  1619, 1623, 1624, 1625, 1626, 1627, 1628, 1630, 1631, 1632, 1633, 1634, 1635,
  1636, 1637, 1638, 1642, 1643, 1644, 1645, 1646, 1647, 1648, 1649, 1651, 1652,
  1653, 1654, 1656, 1657, 1658, 1659, 1660, 1663, 1665, 1666, 1667, 1669, 1670,
  1673, 1674, 1675, 1676, 1677, 1678, 1679, 1680, 1681, 1682, 1683, 1684, 1685,
  1686, 1687, 1688, 1689, 1690, 1691, 1692, 1694, 1695, 1696, 1697, 1699, 1700,
  1701, 1702, 1704, 1707, 1708, 1709, 1712, 1713, 1714, 1715, 1716, 1717, 1718,
  1719, 1720, 1721, 1722, 1723, 1724, 1725, 1726, 1727, 1728, 1730, 1731, 1732,
  1733, 1734, 1735, 1736, 1737, 1738, 1740, 1741, 1742, 1744, 1745, 1746, 1747,
  1748, 1749, 1752, 1753, 1754, 1756, 1757, 1762, 1763, 1772, 1773, 1774, 1775,
  1776, 1782, 1783, 1784, 1785, 1786, 1787, 1788, 1789, 1791, 1792, 1793, 1794,
  1795, 1796, 1797, 1800, 1801, 1802, 1803, 1804, 1805, 1806, 1807, 1808, 1809,
  1811, 1814, 1815, 1816, 1817, 1818, 1820, 1822, 1823, 1824, 1832, 1833, 1844,
  1845, 1846, 1847, 1852, 1853, 1854, 1856, 1860, 1862, 1863, 1864, 1865, 1866,
  1867, 1868, 1869, 1870, 1871, 1872, 1873, 1874, 1875, 1880, 1882, 1884, 1885,
  1890, 1891, 1892, 1893, 1895, 1896, 1897, 1898, 1899, 1902, 1903, 1904, 1905,
  1906, 1907, 1908, 1911, 1912, 1913, 1914, 1918, 1919, 1920, 1921, 1922, 1923,
  1925, 1926, 1927, 1928, 1929, 1930, 1932, 1933, 1934, 1936, 1937, 1938, 1940,
  1941, 1942, 1943, 1944, 1945, 1946, 1947, 1948, 1950, 1951, 1953, 1955, 1957,
  1958, 1960, 1961, 1962, 1963, 1964, 1965, 1966, 1967, 1968, 1969, 1971, 1972,
  1973, 1974, 1975, 1976, 1977, 1978, 1981, 1982, 1983, 1984, 1985, 1986, 1987,
  1988, 1991, 1992, 1993, 1994, 1996, 1997, 2000, 2001, 2002, 2003, 2004, 2006,
  2007, 2009, 2010, 2012, 2013, 2014, 2015, 2016, 2017, 2019, 2022, 2023, 2024,
  2025, 2027, 2028, 2034, 2035, 2036, 2037, 2042, 2043, 2046, 2052, 2053, 2054,
  2056, 2057, 2058, 2063, 2065, 2067, 2068, 2072, 2073, 2074, 2075, 2087, 2088,
  2103, 2105, 2108, 2112, 2113, 2114, 2115, 2116, 2117, 2123, 2124, 2126, 2127,
  2149, 2206, 2207, 2208, 2300, 2301, 2302, 2303, 2304, 2305, 2314, 2316, 2318,
  2322, 2325, 2333, 2336, 2338, 2340, 2345, 2350, 2353, 2354, 2360, 2362, 2363,
  2364, 2400, 2405, 2406, 2414, 2416, 2500, 2501, 2502, 2503, 2504, 2505, 2510,
  2512, 2513, 2514, 2515, 2516, 2517, 2518, 2520, 2523, 2525, 2532, 2533, 2534,
  2535, 2536, 2537, 2538, 2540, 2542, 2543, 2544, 2545, 2552, 2553, 2554, 2555,
  2556, 2557, 2558, 2560, 2562, 2563, 2564, 2565, 2572, 2575, 2576, 2577, 2603,
  2604, 2605, 2606, 2607, 2608, 2610, 2612, 2613, 2615, 2616, 2710, 2712, 2713,
  2714, 2715, 2716, 2717, 2718, 2720, 2722, 2723, 2732, 2733, 2735, 2736, 2738,
  2740, 2742, 2743, 2744, 2745, 2746, 2747, 2748, 2762, 2800, 2802, 2803, 2805,
  2806, 2807, 2812, 2813, 2814, 2822, 2823, 2824, 2825, 2826, 2827, 2828, 2829,
  2830, 2832, 2842, 2843, 2852, 2853, 2854, 2855, 2856, 2857, 2863, 2864, 2873,
  2882, 2883, 2884, 2885, 2886, 2887, 2888, 2889, 2900, 2902, 2903, 2904, 2905,
  2906, 2907, 2908, 2912, 2914, 2915, 2916, 2922, 2923, 2924, 2925, 2926, 2932,
  2933, 2935, 2942, 2943, 2944, 2946, 2947, 2950, 2952, 2953, 2954, 3000, 3001,
  3002, 3003, 3004, 3005, 3006, 3007, 3008, 3010, 3011, 3012, 3013, 3014, 3015,
  3017, 3018, 3019, 3020, 3024, 3027, 3029, 3030, 3032, 3033, 3034, 3035, 3036,
  3037, 3038, 3039, 3040, 3041, 3042, 3043, 3044, 3045, 3046, 3047, 3048, 3049,
  3050, 3052, 3053, 3054, 3063, 3065, 3066, 3067, 3068, 3071, 3072, 3073, 3074,
  3075, 3076, 3077, 3078, 3080, 3082, 3083, 3084, 3085, 3086, 3087, 3088, 3089,
  3090, 3095, 3096, 3097, 3098, 3099, 3110, 3111, 3112, 3113, 3114, 3115, 3116,
  3122, 3123, 3124, 3125, 3126, 3127, 3128, 3132, 3144, 3145, 3147, 3148, 3150,
  3152, 3153, 3154, 3155, 3156, 3157, 3158, 3159, 3172, 3173, 3174, 3175, 3176,
  3177, 3178, 3179, 3182, 3183, 3184, 3185, 3186, 3202, 3203, 3204, 3205, 3206,
  3207, 3208, 3210, 3212, 3213, 3214, 3215, 3216, 3225, 3226, 3232, 3233, 3234,
  3235, 3236, 3237, 3238, 3250, 3251, 3252, 3253, 3254, 3255, 3256, 3257, 3262,
  3263, 3264, 3266, 3267, 3268, 3270, 3271, 3272, 3273, 3274, 3280, 3282, 3283,
  3284, 3285, 3286, 3292, 3293, 3294, 3295, 3296, 3297, 3298, 3302, 3303, 3305,
  3306, 3307, 3308, 3309, 3312, 3313, 3314, 3315, 3317, 3321, 3322, 3323, 3324,
  3325, 3326, 3360, 3362, 3363, 3365, 3366, 3367, 3368, 3372, 3373, 3374, 3375,
  3376, 3377, 3380, 3400, 3401, 3402, 3412, 3413, 3414, 3415, 3416, 3417, 3418,
  3419, 3421, 3422, 3423, 3424, 3425, 3426, 3427, 3428, 3429, 3432, 3433, 3434,
  3435, 3436, 3437, 3438, 3439, 3452, 3453, 3454, 3455, 3456, 3457, 3462, 3463,
  3464, 3465, 3472, 3473, 3474, 3475, 3476, 3503, 3504, 3506, 3507, 3508, 3510,
  3512, 3513, 3531, 3532, 3533, 3534, 3535, 3536, 3537, 3538, 3543, 3550, 3551,
  3552, 3553, 3555, 3556, 3557, 3600, 3601, 3602, 3603, 3604, 3605, 3607, 3608,
  3609, 3612, 3613, 3614, 3615, 3616, 3617, 3618, 3619, 3622, 3623, 3624, 3625,
  3626, 3627, 3628, 3629, 3631, 3632, 3633, 3634, 3635, 3636, 3638, 3645, 3646,
  3647, 3652, 3653, 3654, 3655, 3656, 3657, 3658, 3661, 3662, 3663, 3664, 3665,
  3671, 3672, 3673, 3674, 3700, 3702, 3703, 3704, 3705, 3706, 3707, 3711, 3713,
  3714, 3715, 3716, 3717, 3718, 3722, 3723, 3724, 3725, 3752, 3753, 3754, 3755,
  3756, 3757, 3758, 3762, 3763, 3764, 3765, 3766, 3770, 3771, 3772, 3773, 3775,
  3776, 3777, 3778, 3780, 3781, 3782, 3783, 3784, 3785, 3792, 3800, 3801, 3802,
  3803, 3804, 3805, 3806, 3807, 3812, 3813, 3814, 3815, 3816, 3818, 3822, 3823,
  3824, 3825, 3826, 3852, 3853, 3854, 3855, 3856, 3857, 3858, 3860, 3862, 3863,
  3864, 3900, 3901, 3902, 3903, 3904, 3905, 3906, 3907, 3908, 3910, 3911, 3912,
  3913, 3914, 3916, 3917, 3918, 3919, 3920, 3922, 3923, 3924, 3925, 3926, 3927,
  3928, 3929, 3930, 3931, 3932, 3933, 3934, 3935, 3937, 3938, 3939, 3940, 3942,
  3943, 3944, 3945, 3946, 3947, 3948, 3949, 3951, 3952, 3953, 3954, 3955, 3956,
  3957, 3960, 3961, 3963, 3965, 3966, 3967, 3968, 3970, 3971, 3972, 3973, 3974,
  3975, 3976, 3977, 3978, 3979, 3982, 3983, 3984, 3985, 3986, 3987, 3988, 3989,
  3991, 3992, 3993, 3994, 3995, 3996, 3997, 3998, 3999, 4000, 4001, 4002, 4003,
  4004, 4005, 4007, 4009, 4010, 4011, 4012, 4013, 4014, 4015, 4016, 4017, 4018,
  4019, 4020, 4021, 4024, 4025, 4028, 4030, 4031, 4032, 4033, 4034, 4035, 4036,
  4039, 4040, 4041, 4042, 4051, 4052, 4053, 4054, 4055, 4056, 4057, 4058, 4059,
  4070, 4075, 4089, 4091, 4101, 4102, 4103, 4104, 4105, 4106, 4107, 4108, 4112,
  4114, 4115, 4116, 4117, 4118, 4123, 4124, 4125, 4126, 4127, 4130, 4132, 4133,
  4134, 4140, 4142, 4143, 4144, 4145, 4146, 4147, 4148, 4153, 4202, 4203, 4204,
  4206, 4207, 4208, 4222, 4223, 4224, 4225, 4226, 4227, 4228, 4229, 4232, 4233,
  4234, 4242, 4243, 4244, 4245, 4246, 4247, 4252, 4253, 4254, 4302, 4303, 4304,
  4305, 4310, 4312, 4313, 4314, 4315, 4316, 4317, 4322, 4323, 4324, 4325, 4332,
  4333, 4334, 4402, 4410, 4411, 4412, 4413, 4414, 4415, 4416, 4417, 4418, 4419,
  4421, 4422, 4423, 4424, 4425, 4426, 4431, 4432, 4433, 4434, 4435, 4436, 4437,
  4438, 4441, 4442, 4443, 4444, 4445, 4446, 4447, 4448, 4450, 4451, 4452, 4453,
  4455, 4456, 4457, 4458, 4460, 4461, 4462, 4463, 4464, 4465, 4466, 4467, 4468,
  4469, 4492, 4493, 4494, 4495, 4496, 4497, 4500, 4501, 4502, 4503, 4509, 4512,
  4513, 4514, 4515, 4522, 4523, 4524, 4525, 4528, 4532, 4533, 4534, 4535, 4536,
  4537, 4538, 4539, 4542, 4543, 4552, 4553, 4554, 4556, 4557, 4558, 4562, 4563,
  4564, 4565, 4566, 4571, 4573, 4574, 4576, 4577, 4578, 4579, 4581, 4582, 4583,
  4584, 4585, 4586, 4587, 4588, 4600, 4601, 4604, 4605, 4609, 4611, 4612, 4613,
  4614, 4615, 4616, 4617, 4618, 4620, 4621, 4622, 4623, 4624, 4625, 4626, 4628,
  4629, 4630, 4632, 4633, 4634, 4640, 4652, 4653, 4654, 4655, 4656, 4657, 4658,
  4663, 4665, 4702, 4703, 4704, 4710, 4712, 4713, 4714, 4715, 4716, 4717, 4718,
  4719, 4800, 4801, 4802, 4803, 4805, 4806, 4807, 4808, 4809, 4810, 4812, 4813,
  4814, 4852, 4853, 4856, 4900, 4901, 4911, 4912, 4913, 4914, 4915, 4916, 4917,
  4919, 4922, 4923, 4924, 4932, 4933, 4934, 4935, 4936, 4937, 4938, 4942, 4943,
  4944, 4950, 4952, 4953, 4954, 4955, 5000, 5001, 5004, 5012, 5013, 5014, 5015,
  5017, 5018, 5022, 5023, 5024, 5025, 5026, 5027, 5028, 5030, 5032, 5033, 5034,
  5035, 5036, 5037, 5040, 5042, 5043, 5044, 5046, 5050, 5053, 5054, 5056, 5057,
  5058, 5060, 5062, 5063, 5064, 5070, 5072, 5073, 5074, 5075, 5076, 5077, 5078,
  5079, 5080, 5082, 5083, 5084, 5085, 5102, 5103, 5105, 5106, 5107, 5108, 5112,
  5113, 5116, 5200, 5201, 5210, 5212, 5213, 5222, 5223, 5225, 5232, 5233, 5234,
  5235, 5236, 5237, 5242, 5243, 5244, 5245, 5246, 5272, 5273, 5274, 5275, 5276,
  5277, 5300, 5301, 5303, 5304, 5305, 5306, 5312, 5313, 5314, 5315, 5316, 5317,
  5318, 5322, 5323, 5324, 5325, 5326, 5330, 5332, 5333, 5334, 5400, 5401, 5402,
  5404, 5405, 5406, 5408, 5412, 5413, 5415, 5416, 5417, 5420, 5423, 5425, 5426,
  5430, 5432, 5436, 5442, 5443, 5444, 5445, 5452, 5453, 5454, 5462, 5463, 5464,
  5465, 5466, 5467, 5502, 5503, 5504, 5505, 5506, 5507, 5512, 5522, 5524, 5525,
  5600, 5603, 5604, 5605, 5606, 5607, 5608, 5610, 5611, 5612, 5613, 5614, 5615,
  5616, 5617, 5618, 5619, 5620, 5621, 5622, 5623, 5624, 5625, 5626, 5627, 5628,
  5630, 5632, 5634, 5636, 5637, 5642, 5643, 5644, 5645, 5646, 5647, 5702, 5703,
  5704, 5705, 5706, 5707, 5708, 5712, 5722, 5723, 5724, 5725, 5726, 5727, 5728,
  5732, 5733, 5734, 5735, 5736, 5737, 5742, 5745, 5746, 6000, 6002, 6003, 6004,
  6005, 6006, 6007, 6009, 6010, 6011, 6012, 6013, 6014, 6015, 6016, 6017, 6018,
  6019, 6020, 6021, 6022, 6023, 6024, 6025, 6026, 6027, 6028, 6030, 6031, 6032,
  6033, 6034, 6035, 6036, 6037, 6038, 6039, 6042, 6043, 6044, 6045, 6047, 6048,
  6052, 6053, 6055, 6056, 6060, 6061, 6062, 6063, 6064, 6066, 6067, 6068, 6072,
  6073, 6074, 6078, 6083, 6084, 6085, 6086, 6102, 6103, 6105, 6106, 6110, 6112,
  6113, 6114, 6122, 6123, 6125, 6126, 6130, 6132, 6133, 6142, 6143, 6144, 6145,
  6146, 6147, 6152, 6153, 6154, 6156, 6160, 6161, 6162, 6163, 6166, 6167, 6170,
  6173, 6182, 6192, 6196, 6197, 6203, 6204, 6205, 6206, 6207, 6208, 6210, 6211,
  6212, 6213, 6214, 6215, 6216, 6217, 6218, 6221, 6222, 6231, 6232, 6233, 6234,
  6235, 6236, 6242, 6243, 6244, 6245, 6246, 6247, 6248, 6252, 6253, 6260, 6262,
  6263, 6264, 6265, 6274, 6275, 6276, 6277, 6280, 6281, 6283, 6284, 6285, 6286,
  6287, 6288, 6289, 6294, 6295, 6300, 6301, 6302, 6303, 6312, 6313, 6314, 6315,
  6317, 6318, 6319, 6330, 6331, 6332, 6333, 6340, 6341, 6343, 6344, 6345, 6346,
  6353, 6354, 6356, 6362, 6363, 6365, 6370, 6371, 6372, 6373, 6374, 6375, 6376,
  6377, 6382, 6383, 6386, 6387, 6388, 6390, 6391, 6402, 6403, 6404, 6405, 6410,
  6414, 6415, 6416, 6417, 6418, 6422, 6423, 6424, 6430, 6431, 6432, 6433, 6434,
  6436, 6438, 6440, 6441, 6442, 6443, 6452, 6454, 6460, 6461, 6462, 6463, 6464,
  6465, 6466, 6467, 6468, 6469, 6472, 6473, 6474, 6475, 6476, 6482, 6484, 6485,
  6487, 6490, 6491, 6493, 6500, 6501, 6503, 6506, 6511, 6512, 6513, 6514, 6515,
  6516, 6517, 6518, 6523, 6524, 6525, 6526, 6527, 6528, 6530, 6532, 6533, 6534,
  6535, 6537, 6538, 6540, 6541, 6542, 6543, 6544, 6545, 6546, 6548, 6549, 6556,
  6557, 6558, 6562, 6563, 6565, 6571, 6572, 6573, 6574, 6575, 6576, 6577, 6578,
  6579, 6582, 6583, 6584, 6590, 6591, 6592, 6593, 6594, 6595, 6596, 6597, 6598,
  6599, 6600, 6601, 6602, 6604, 6605, 6611, 6612, 6613, 6614, 6616, 6618, 6622,
  6631, 6632, 6633, 6634, 6635, 6636, 6637, 6644, 6645, 6646, 6647, 6648, 6652,
  6653, 6654, 6655, 6656, 6657, 6658, 6659, 6661, 6662, 6663, 6664, 6670, 6672,
  6673, 6674, 6675, 6676, 6677, 6678, 6682, 6683, 6684, 6685, 6690, 6692, 6693,
  6694, 6695, 6696, 6702, 6703, 6705, 6707, 6710, 6713, 6714, 6715, 6716, 6717,
  6718, 6719, 6720, 6721, 6722, 6723, 6724, 6742, 6743, 6744, 6745, 6746, 6747,
  6748, 6749, 6760, 6763, 6764, 6772, 6773, 6774, 6775, 6776, 6777, 6780, 6781,
  6802, 6803, 6804, 6805, 6806, 6807, 6808, 6809, 6810, 6814, 6815, 6816, 6817,
  6818, 6821, 6822, 6823, 6825, 6826, 6827, 6828, 6830, 6832, 6833, 6834, 6835,
  6836, 6837, 6838, 6839, 6850, 6852, 6853, 6854, 6855, 6862, 6863, 6864, 6865,
  6866, 6867, 6872, 6873, 6874, 6875, 6877, 6883, 6900, 6901, 6902, 6903, 6904,
  6905, 6906, 6907, 6908, 6911, 6912, 6913, 6914, 6915, 6916, 6917, 6918, 6919,
  6921, 6922, 6924, 6925, 6926, 6927, 6928, 6929, 6930, 6932, 6933, 6934, 6935,
  6936, 6937, 6938, 6939, 6942, 6943, 6944, 6945, 6946, 6947, 6948, 6949, 6950,
  6951, 6952, 6953, 6954, 6955, 6956, 6957, 6958, 6959, 6960, 6962, 6963, 6964,
  6965, 6966, 6967, 6968, 6974, 6976, 6977, 6978, 6979, 6980, 6981, 6982, 6983,
  6984, 6986, 6987, 6988, 6989, 6990, 6991, 6992, 6993, 6994, 6995, 6997, 6998,
  6999, 7000, 7001, 7003, 7004, 7006, 7007, 7012, 7013, 7014, 7015, 7016, 7017,
  7018, 7019, 7023, 7026, 7027, 7028, 7029, 7031, 7032, 7050, 7056, 7057, 7058,
  7062, 7063, 7064, 7074, 7075, 7076, 7077, 7078, 7082, 7083, 7084, 7104, 7106,
  7107, 7109, 7110, 7111, 7112, 7113, 7114, 7115, 7116, 7122, 7126, 7127, 7128,
  7130, 7132, 7134, 7137, 7138, 7141, 7142, 7143, 7144, 7145, 7146, 7147, 7148,
  7149, 7151, 7152, 7153, 7154, 7155, 7156, 7157, 7158, 7159, 7162, 7163, 7164,
  7165, 7166, 7167, 7168, 7172, 7173, 7174, 7175, 7176, 7180, 7182, 7183, 7184,
  7185, 7186, 7187, 7188, 7189, 7200, 7202, 7203, 7204, 7205, 7206, 7208, 7210,
  7212, 7213, 7214, 7215, 7220, 7222, 7223, 7224, 7226, 7228, 7230, 7231, 7232,
  7233, 7235, 7240, 7241, 7242, 7243, 7244, 7245, 7246, 7247, 7249, 7250, 7252,
  7260, 7265, 7270, 7272, 7276, 7277, 7278, 7302, 7303, 7304, 7306, 7307, 7310,
  7312, 7313, 7314, 7315, 7317, 7320, 7323, 7324, 7325, 7326, 7402, 7403, 7404,
  7405, 7407, 7408, 7411, 7412, 7413, 7414, 7415, 7416, 7417, 7418, 7419, 7421,
  7422, 7423, 7424, 7425, 7426, 7427, 7428, 7430, 7431, 7432, 7433, 7434, 7435,
  7436, 7437, 7438, 7440, 7442, 7443, 7444, 7445, 7446, 7447, 7448, 7450, 7451,
  7452, 7453, 7454, 7455, 7456, 7457, 7458, 7459, 7460, 7462, 7463, 7464, 7472,
  7473, 7477, 7482, 7484, 7492, 7493, 7494, 7500, 7502, 7503, 7504, 7505, 7512,
  7513, 7514, 7515, 7516, 7517, 7522, 7523, 7524, 7525, 7526, 7527, 7530, 7532,
  7533, 7534, 7535, 7536, 7537, 7542, 7543, 7545, 7546, 7550, 7551, 7552, 7553,
  7554, 7556, 7557, 7558, 7559, 7560, 7562, 7563, 7602, 7603, 7604, 7605, 7606,
  7608, 7610, 7710, 7741, 7742, 7743, 7744, 7745, 7746, 7747, 7748, 8000, 8001,
  8002, 8003, 8004, 8005, 8006, 8008, 8010, 8011, 8012, 8015, 8017, 8018, 8021,
  8022, 8023, 8024, 8025, 8026, 8027, 8030, 8031, 8032, 8033, 8034, 8036, 8037,
  8038, 8039, 8040, 8041, 8042, 8044, 8045, 8046, 8047, 8048, 8049, 8050, 8051,
  8052, 8053, 8055, 8057, 8058, 8059, 8060, 8061, 8063, 8064, 8068, 8070, 8071,
  8072, 8073, 8074, 8075, 8080, 8081, 8085, 8086, 8087, 8088, 8090, 8091, 8092,
  8093, 8096, 8098, 8099, 8102, 8103, 8104, 8105, 8106, 8107, 8108, 8109, 8112,
  8113, 8114, 8115, 8117, 8118, 8121, 8122, 8123, 8124, 8125, 8126, 8127, 8130,
  8132, 8133, 8134, 8135, 8136, 8140, 8142, 8143, 8150, 8152, 8153, 8154, 8155,
  8156, 8157, 8158, 8162, 8164, 8165, 8166, 8172, 8173, 8174, 8175, 8180, 8181,
  8182, 8183, 8184, 8185, 8187, 8192, 8193, 8194, 8195, 8196, 8197, 8200, 8201,
  8202, 8203, 8204, 8207, 8208, 8210, 8212, 8213, 8214, 8215, 8216, 8217, 8218,
  8219, 8222, 8223, 8224, 8225, 8226, 8228, 8231, 8232, 8233, 8234, 8235, 8236,
  8238, 8239, 8240, 8241, 8242, 8243, 8245, 8246, 8247, 8248, 8252, 8253, 8254,
  8255, 8259, 8260, 8261, 8262, 8263, 8264, 8265, 8266, 8267, 8268, 8269, 8272,
  8273, 8274, 8280, 8285, 8300, 8301, 8302, 8303, 8304, 8305, 8306, 8307, 8308,
  8309, 8310, 8311, 8312, 8314, 8315, 8317, 8320, 8322, 8325, 8326, 8330, 8331,
  8332, 8340, 8342, 8343, 8344, 8345, 8350, 8352, 8353, 8354, 8355, 8356, 8357,
  8360, 8362, 8363, 8370, 8371, 8372, 8374, 8376, 8400, 8401, 8402, 8403, 8404,
  8405, 8406, 8408, 8409, 8410, 8411, 8412, 8413, 8414, 8415, 8416, 8418, 8421,
  8422, 8424, 8425, 8426, 8427, 8428, 8442, 8444, 8447, 8450, 8451, 8452, 8453,
  8454, 8455, 8457, 8458, 8459, 8460, 8461, 8462, 8463, 8464, 8465, 8466, 8467,
  8468, 8471, 8472, 8474, 8475, 8476, 8477, 8478, 8479, 8482, 8483, 8484, 8486,
  8487, 8488, 8489, 8492, 8493, 8494, 8495, 8496, 8497, 8498, 8499, 8500, 8501,
  8502, 8503, 8505, 8506, 8507, 8508, 8509, 8510, 8512, 8514, 8520, 8522, 8523,
  8524, 8525, 8526, 8530, 8532, 8535, 8536, 8537, 8540, 8542, 8543, 8544, 8545,
  8546, 8547, 8548, 8552, 8553, 8554, 8555, 8556, 8558, 8560, 8561, 8564, 8565,
  8566, 8570, 8572, 8573, 8574, 8575, 8576, 8577, 8580, 8581, 8582, 8583, 8584,
  8585, 8586, 8587, 8588, 8589, 8590, 8592, 8593, 8594, 8595, 8596, 8597, 8598,
  8599, 8600, 8602, 8603, 8604, 8605, 8606, 8607, 8608, 8610, 8612, 8613, 8614,
  8615, 8616, 8617, 8618, 8620, 8621, 8622, 8623, 8624, 8625, 8626, 8627, 8630,
  8632, 8633, 8634, 8635, 8636, 8637, 8638, 8639, 8640, 8645, 8646, 8700, 8702,
  8703, 8704, 8706, 8707, 8708, 8712, 8713, 8714, 8715, 8716, 8717, 8718, 8722,
  8723, 8725, 8726, 8727, 8730, 8732, 8733, 8734, 8735, 8737, 8738, 8739, 8740,
  8750, 8752, 8753, 8754, 8755, 8756, 8757, 8758, 8759, 8762, 8765, 8766, 8767,
  8772, 8773, 8774, 8775, 8777, 8782, 8783, 8784, 8800, 8802, 8803, 8804, 8805,
  8806, 8807, 8808, 8810, 8813, 8815, 8816, 8820, 8824, 8825, 8832, 8833, 8834,
  8835, 8836, 8840, 8841, 8842, 8843, 8844, 8845, 8846, 8847, 8849, 8852, 8853,
  8854, 8855, 8856, 8857, 8858, 8862, 8863, 8864, 8865, 8866, 8867, 8868, 8870,
  8872, 8873, 8874, 8877, 8878, 8879, 8880, 8881, 8882, 8883, 8884, 8885, 8886,
  8887, 8888, 8889, 8890, 8892, 8893, 8894, 8895, 8896, 8897, 8898, 8901, 8902,
  8903, 8904, 8905, 8906, 8907, 8908, 8909, 8910, 8911, 8912, 8913, 8914, 8915,
  8916, 8917, 8918, 8919, 8920, 8925, 8926, 8930, 8932, 8933, 8934, 8940, 8942,
  8951, 8952, 8953, 8954, 8955, 8956, 8957, 8962, 8964, 8965, 8966, 8967, 8970,
  9000, 9001, 9004, 9006, 9007, 9008, 9010, 9011, 9012, 9013, 9014, 9015, 9016,
  9020, 9021, 9023, 9024, 9026, 9027, 9028, 9029, 9030, 9032, 9033, 9034, 9035,
  9036, 9037, 9038, 9042, 9043, 9044, 9050, 9052, 9053, 9054, 9055, 9056, 9057,
  9058, 9062, 9063, 9064, 9100, 9102, 9103, 9104, 9105, 9107, 9108, 9112, 9113,
  9114, 9115, 9116, 9122, 9123, 9125, 9126, 9127, 9200, 9201, 9203, 9204, 9205,
  9212, 9213, 9214, 9215, 9216, 9217, 9220, 9223, 9225, 9230, 9231, 9240, 9242,
  9243, 9244, 9245, 9246, 9247, 9248, 9249, 9300, 9301, 9304, 9305, 9306, 9308,
  9312, 9313, 9314, 9315, 9320, 9322, 9323, 9325, 9326, 9327, 9400, 9401, 9402,
  9403, 9404, 9405, 9410, 9411, 9413, 9422, 9423, 9424, 9425, 9426, 9427, 9428,
  9430, 9434, 9435, 9436, 9437, 9442, 9443, 9444, 9445, 9450, 9451, 9452, 9453,
  9462, 9463, 9464, 9465, 9466, 9467, 9468, 9469, 9470, 9471, 9472, 9473, 9475,
  9476, 9477, 9478, 9479, 9485, 9486, 9487, 9488, 9489, 9490, 9491, 9492, 9493,
  9494, 9495, 9496, 9497, 9498, 9500, 9501, 9502, 9503, 9504, 9506, 9507, 9508,
  9512, 9514, 9515, 9517, 9523, 9524, 9525, 9526, 9527, 9532, 9533, 9534, 9535,
  9536, 9542, 9543, 9545, 9546, 9547, 9548, 9552, 9553, 9554, 9555, 9556, 9562,
  9565, 9573, 9601, 9602, 9604, 9606, 9607, 9608, 9612, 9613, 9614, 9615, 9620,
  9621, 9622, 9630, 9631, 9633, 9642, 9643, 9650, 9651, 9652, 9655, 9656, 9657,
  9658,
];
