import { useAppState, ACTIONS } from "lib/hooks/useAppState";
import {
  useFormularState,
  ACTIONS as FORMACTIONS,
} from "lib/hooks/useFormularState";
import useTranslation from "lib/hooks/useTranslation";
import React, { useEffect, useState } from "react";
import { PLZ_CH, PLZ_LIE, PREISE, SCREENS } from "lib/constants";
import { jsUcfirst } from "lib/library";
import { fillSAPform } from "helper/fillSAPform";
import { MODE, SAP_BUTTON_ID, SAP_IDS } from "helper/constants";
import { createData, updateData } from "lib/fetchData";
import useApiURL from "lib/hooks/useApiURL";

const Sofortpreis = () => {
  const { t, language } = useTranslation();
  const { dispatch } = useAppState();
  const { formState, formDispatch } = useFormularState();
  const [fehler, setFehler] = useState();
  const [error, setError] = useState("");
  const { base_url } = useApiURL();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  useEffect(() => {
    // Den URL Parameter in das Formular schrieben damit es an SAP gesendet werden kann....
    //fillSAPform(MODE, formState, SAP_IDS, language);
    //document.getElementById(SAP_BUTTON_ID[MODE + "_id"][language]).click();
  }, []);

  /*   useEffect(() => {
    const getData = async () => {
      const data = {
        ...formState,
        email_sofortpreis_flasche: t("email_sofortpreis_flasche"),
        email_sofortpreis_springseil: t("email_sofortpreis_springseil"),
        email_sofortpreis_armband: t("email_sofortpreis_armband"),
        email_sofortpreis_blackroll: t("email_sofortpreis_blackroll"),
        email_sofortpreis_fitnessband: t("email_sofortpreis_fitnessband"),
        email_sofortpreis_yogamatte: t("email_sofortpreis_yogamatte"),
        language,
        token: state.token,
      };
      const response = await createData(data, base_url);
      console.log("createData Response ", response);
      formDispatch({
        type: ACTIONS.SET_DATA,
        payload: {
          key: "id",
          value: response.payload.id,
        },
      });
    };

    getData();
  }, []); */

  const handleChange = (event) => {
    setFehler("");
    setError("");

    if (event.target.name !== "email") {
      event.target.value = jsUcfirst(event.target.value);
    }

    if (event.target.name === "vorname" || event.target.name === "nachname") {
      event.target.value = event.target.value.match(
        /[\p{Letter}\p{Mark}\s-]+/gu
      );
    }
    if (event.target.name === "ort" || event.target.name === "strasse") {
      event.target.value = event.target.value.match(
        /[\p{Letter}\p{Mark}.\s-]+/gu
      );
    }

    if (event.target.name === "nummer") {
      event.target.value = event.target.value.match(
        /[\p{Letter}\p{Number}\p{Mark}]+/gu
      );
    }

    if (event.target.name === "email") {
      event.target.value = event.target.value.toLowerCase().trim();
    }

    formDispatch({
      type: FORMACTIONS.SET_DATA,
      payload: {
        key: event.target.name,
        value: event.target.value,
      },
    });
  };

  const validate = () => {
    if (formState.vorname === "") {
      setFehler(t("formular_fehler"));
      setError("vorname");
      return false;
    }
    if (formState.nachname === "") {
      setFehler(t("formular_fehler"));
      setError("nachname");
      return false;
    }
    if (formState.strasse === "") {
      setFehler(t("formular_fehler"));
      setError("strasse");
      return false;
    }
    if (formState.nummer === "") {
      setFehler(t("formular_fehler"));
      setError("nummer");
      return false;
    }
    if (formState.plz === "") {
      setFehler(t("formular_fehler"));
      setError("plz");
      return false;
    }

    if (PLZ_LIE.includes(parseInt(formState.plz))) {
      setFehler(t("Land.err"));
      setError("plz");
      return false;
    }

    if (!PLZ_CH.includes(parseInt(formState.plz))) {
      setFehler(t("Plz.err"));
      setError("plz");
      return false;
    }

    if (formState.ort === "") {
      setFehler(t("formular_fehler"));
      setError("ort");
      return false;
    }
    return true;
  };

  const handleClickSenden = async () => {
    if (!validate()) return;
    const data = {
      ...formState,
    };
    const response = await updateData(data, base_url);
    console.log("updateData Response ", response);
    dispatch({
      type: ACTIONS.SET_SCREEN,
      payload: SCREENS.DANKE,
    });
  };

  return (
    <>
      <div className="text-3xl font-extrabold italic mt-8 px-8 md:text-[56px] md:leading-[67px] uppercase">
        {t("sofortpreis_titel")}
      </div>
      <div className="px-8 mt-6">
        <img
          className="leading-none block align-middle"
          src={`/images/sofortpreise_ohne/${PREISE[formState.typ]}.jpg`}
          alt=""
        ></img>
        <div className=" text-3xl font-extrabold italic mt-8 text-white md:text-[46px] md:leading-[55px]">
          {t("sofortpreise_" + formState.typ.toLowerCase())}
        </div>
        <div>{`${t("deine_id")} ${formState.preis_id}`}</div>
      </div>
      <div className="text-xl font-extrabold italic mt-8 px-8 md:text-[26px] md:leading-[36px]">
        {t("sofortpreis_post")}
      </div>
      <div className="text-lg leading-7 mt-4 px-8  md:text-[22px] md:leading-[36px]">
        {t("sofortpreis_wochenverlosung")}
      </div>
      <div className="px-8 mb-28">
        <input
          value={formState.vorname}
          type="text"
          onChange={handleChange}
          name="vorname"
          placeholder={t("formular_vorname")}
          className={`${
            error === "vorname" ? "bg-[#FFED00]" : "bg-white"
          } mx-auto block form-input px-4 py-6 rounded-md w-full md:w-8/12 text-left mt-8 md:mt-8 text-[#000] text-base font-normal border-none focus:border-transparent focus:ring-0`}
        ></input>

        <input
          value={formState.nachname}
          type="text"
          onChange={handleChange}
          name="nachname"
          placeholder={t("formular_nachname")}
          className={`${
            error === "nachname" ? "bg-[#FFED00]" : "bg-white"
          } mx-auto block form-input px-4 py-6 rounded-md w-full md:w-8/12 text-left mt-4 text-[#000] text-base font-normal border-none focus:border-transparent focus:ring-0`}
        ></input>

        <input
          value={formState.strasse}
          type="text"
          onChange={handleChange}
          name="strasse"
          placeholder={t("formular_strasse")}
          className={`${
            error === "strasse" ? "bg-[#FFED00]" : "bg-white"
          } mx-auto block form-input px-4 py-6 rounded-md w-full md:w-8/12 text-left mt-4 text-[#000] text-base font-normal border-none focus:border-transparent focus:ring-0`}
        ></input>

        <input
          value={formState.nummer}
          type="text"
          onChange={handleChange}
          name="nummer"
          placeholder={t("formular_nummer")}
          className={`${
            error === "nummer" ? "bg-[#FFED00]" : "bg-white"
          } mx-auto block form-input px-4 py-6 rounded-md w-full md:w-8/12 text-left mt-4 text-[#000] text-base font-normal border-none focus:border-transparent focus:ring-0`}
        ></input>

        <input
          value={formState.plz}
          type="text"
          onChange={handleChange}
          name="plz"
          placeholder={t("formular_plz")}
          className={`${
            error === "plz" ? "bg-[#FFED00]" : "bg-white"
          } mx-auto block form-input px-4 py-6 rounded-md w-full md:w-8/12 text-left mt-4 text-[#000] text-base font-normal border-none focus:border-transparent focus:ring-0`}
        ></input>

        <input
          value={formState.ort}
          type="text"
          onChange={handleChange}
          name="ort"
          placeholder={t("formular_ort")}
          className={`${
            error === "ort" ? "bg-[#FFED00]" : "bg-white"
          } mx-auto block form-input px-4 py-6 rounded-md w-full md:w-8/12 text-left mt-4 text-[#000] text-base font-normal border-none focus:border-transparent focus:ring-0`}
        ></input>

        <div className="md:mx-auto md:w-8/12 ">
          <div className="text-lg leading-7 mt-2 ">
            {t("startseite_pflichtfeld")}
          </div>

          {fehler !== "" && (
            <div className="text-lg leading-7 mt-4 text-yellow-300">
              {fehler}
            </div>
          )}
          <button
            className="italic font-extrabold uppercase text-lg text-[#000] bg-[#FFED00] px-8 py-4 mt-4"
            onClick={handleClickSenden}
          >
            {t("sofortpreis_senden_btn")}
          </button>
        </div>
      </div>
    </>
  );
};

export default Sofortpreis;
