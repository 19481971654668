import React from "react";
import box from "./checkbox_not_checked.svg";
import box_checked from "./checkbox_checked.svg";
import styled from "styled-components";
import { StyledParagraph } from "styles/typo";

export const CheckboxWrapper = styled.div`
  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: #000000;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 8px;

  @media (min-width: 768px) {
    font-size: 16px;
  }
`;

const Checked = styled.img`
  width: 24px;
`;

const NotChecked = styled.img`
  width: 24px;
`;

const Label = styled(StyledParagraph)`
  margin-left: 16px;
  text-align: left;
  background-color: ${(props) => (props.error ? "#ff9595" : "#fff")};
`;

export function Checkbox({ checked, onClick, label, error, link }) {
  console.log("Checkbox", error);
  return (
    <>
      <CheckboxWrapper onClick={onClick}>
        <div>
          {checked ? (
            <Checked src={box_checked}></Checked>
          ) : (
            <NotChecked src={box}></NotChecked>
          )}
        </div>
        <Label
          dangerouslySetInnerHTML={{
            __html: label,
          }}
          error={error}
        ></Label>
      </CheckboxWrapper>
    </>
  );
}
