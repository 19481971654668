import React from "react";
import useTranslation from "lib/hooks/useTranslation";
import styled from "styled-components";
import { PREISE } from "lib/constants";

const Hauptpreise = () => {
  const { t } = useTranslation();

  const items = [
    {
      bild: PREISE.FITNESSABO,
      anzahl: "4x",
      text: t("hauptpreise_fitnessabo "),
    },
    {
      bild: PREISE.GARMIN,
      anzahl: "4x",
      text: t("hauptpreise_garmin"),
    },
  ];

  return (
    <>
      <div>
        <div>
          <div className="text-3xl md:text-5xl font-extrabold italic mt-16 md:mt-28 px-8 mb-14 uppercase  md:text-[48px] md:leading-[56px]">
            {t("hauptpreise_titel")}
            <div className="w-[87px] h-[4px] bg-[#FFED00] mt-1"></div>
          </div>

          <>
            {items.map((item, index) => {
              return (
                <div
                  className={`mb-20 md:flex items-center ${
                    index === 1 && "md:flex-row-reverse"
                  }`}
                  key={index}
                >
                  <div className="relative">
                    <div
                      className={`-mt-6 hidden md:block md:absolute ${
                        index === 0 ? "-right-10" : "-left-16"
                      } top-16`}
                    >
                      <div className="bg-[#FFED00] text-black inline text-3xl font-extrabold italic px-4 py-2 md:text-[34px] md:leading-[44px] md:px-6 ml-12">
                        {item.anzahl}
                      </div>
                    </div>
                    <img
                      className="leading-none block align-middle px-8 md:px-0 md:pl-8 md:max-w-[440px]"
                      src={`/images/hauptpreise_mit/${item.bild}.jpg`}
                      alt=""
                    ></img>
                  </div>
                  <div className="-mt-6 md:hidden relative z-50">
                    <div className="bg-[#FFED00] text-black inline text-3xl font-extrabold italic px-4 py-2 md:text-[34px] md:leading-[44px] md:px-5 ml-12">
                      {item.anzahl}
                    </div>
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.text,
                    }}
                    className="text-3xl font-extrabold italic mt-8 px-8 md:text-[34px] md:leading-[44px] md:pl-20 md:px-0 md:mt-0"
                  >
                  </div>
                </div>
              );
            })}
          </>
        </div>
      </div>
    </>
  );
};

const StyledImageContainer = styled.div`
  position: relative;
  max-width: 350px;
  margin: 0 auto;
  padding-top: 32px;
  background-color: white;
`;

const StyledImage = styled.img`
  width: 100%;
  max-width: 440px;
  display: block;
`;

const StyledTeaser = styled.div`
  background-color: #f1f1f1;
`;

export default Hauptpreise;
