import useTranslation from "lib/hooks/useTranslation";
import React from "react";
import {
  ACTIONS as FORMACTIONS,
  useFormularState,
} from "lib/hooks/useFormularState";

const Frage_2 = ({ setFehler }) => {
  const { t } = useTranslation();
  const { formDispatch, formState } = useFormularState();

  const onChange = (event) => {
    setFehler("");
    console.log(event.target.checked);
    // alle Fragen 2 auf false setzen
    const keys = ["F2_A", "F2_B", "F2_C", "F2_D"];
    keys.forEach((key) => {
      formDispatch({
        type: FORMACTIONS.SET_DATA,
        payload: {
          key: key,
          value: false,
        },
      });
    });

    formDispatch({
      type: FORMACTIONS.SET_DATA,
      payload: {
        key: event.target.id,
        value: event.target.checked,
      },
    });
  };

  return (
    <div>
      <div className="px-8 text-lg leading-7 font-extrabold italic md:text-[26px] md:leading-[36px]">
        {t("frage_2")}
      </div>
      <div className="px-8">
        {formState.antworten_2.map((item) => {
          return (
            <div className="flex items-center mt-6">
              <input
                checked={formState[item.kennung]}
                name="radio"
                onChange={onChange}
                id={item.kennung}
                type="radio"
                className="text-black bg-black w-6 h-6 border border-white checked:border-white checked:hover:border-white checked:hover:bg-black focus:border-transparent focus:ring-0 "
              />
              <label htmlFor={item.kennung} className="ml-4 md:text-xl">
                {t("frage_2_antwort_" + item.nummer)}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Frage_2;
