import { AppStateProvider } from "lib/hooks/useAppState";
import { TranslationProvider } from "lib/hooks/useTranslation";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { theme_puretaste } from "styles/theme";
import App from "./App";
import "styles/global.css";
import { FormularStateProvider } from "lib/hooks/useFormularState";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ThemeProvider theme={theme_puretaste}>
      <AppStateProvider>
        <TranslationProvider>
          <FormularStateProvider>
            <App />
          </FormularStateProvider>
        </TranslationProvider>
      </AppStateProvider>
    </ThemeProvider>
  </BrowserRouter>
);
