import React from "react";
import useTranslation from "lib/hooks/useTranslation";
import styled from "styled-components";
import { CARDS } from "lib/constants";

const Cards = () => {
  const { t } = useTranslation();

  const items = [
    {
      bild: CARDS.YOGURT,
      text: t("card_yogurt_text"),
      button: t("card_yogurt_button"),
      link: t("card_yogurt_link"),
    },
    {
      bild: CARDS.PROTEINBEDARF,
      text: t("card_proteinrechner_text"),
      button: t("card_proteinrechner_button"),
      link: t("card_proteinrechner_link"),
    },
  ];

  return (
    <>
      <div className="">
        {items.map((item, index) => {
          return (
            <div
              className={`mb-20 md:flex items-center ${
                index === 1 && "md:flex-row-reverse"
              }`}
              key={index}
            >
              <img
                className="leading-none block align-middle px-8 md:px-0 md:max-w-[440px]"
                src={`/images/cards/${item.bild}.jpg`}
                alt=""
              ></img>
              <div className="px-8 md:px-0">
                <div className="bg-[#FFED00] pb-8 pt-8 md:h-[302px]">
                  <div className="text-black text-3xl font-extrabold italic px-8 md:text-[28px] md:leading-[34px] md:px-16 md:mt-0">
                    {item.text}
                  </div>
                  <div className="px-8 mt-8 md:px-16">
                    <button
                      className="italic font-extrabold uppercase text-lg text-[#fff] bg-[#000] px-8 py-4"
                      onClick={() => {
                        window.open(item.link);
                      }}
                    >
                      {item.button}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

const StyledImageContainer = styled.div`
  position: relative;
  max-width: 350px;
  margin: 0 auto;
  padding-top: 32px;
  background-color: white;
`;

const StyledImage = styled.img`
  width: 100%;
  max-width: 440px;
  display: block;
`;

const StyledTeaser = styled.div`
  background-color: #f1f1f1;
`;

export default Cards;
