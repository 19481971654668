import styled from "styled-components";

export const StyledContainer = styled.div`
  background-color: #000;
  margin: 0 auto;
  width: 100%;
  max-width: ${(props) => props.theme.breakPoints.xxl};
  /*   max-width: 375px;
  position: relative; */

  @media (min-width: ${(props) => props.theme.breakPoints.sm}) {
    /*     padding-left: 30px;
    padding-right: 30px; */
  }
`;

export const StyledSmallContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 700px;
  padding-left: 30px;
  padding-right: 30px;
`;

export const StyledUIButton = styled.button`
  user-select: none;
  cursor: pointer;
  display: block;
  margin: 0 auto;
  -webkit-font-smoothing: antialiased;
  padding: 10px 32px;
  text-align: center;
  background: #000000;
  color: white;
  font-family: "futura-pt";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 24px;
  /* identical to box height, or 120% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;
  a {
    text-decoration: none;
  }
`;

export const StyledDashboardButton = styled.button`
  background-color: #fff;
  color: #ca0018;
  border: 0.25rem solid #fff;
  box-shadow: 0 3px 15px 0 rgb(189 189 189 / 30%);
  display: inline-block;
  text-align: center;
  padding: 0.8125rem 1.625rem;
  border-radius: 6px;
  cursor: pointer;
  width: 100%;
  max-width: 300px;
  white-space: normal;
  font-family: FFDaxWebPro, Sans-Serif;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.25px;

  :hover {
    box-shadow: 0 3px 15px 0 rgb(189 189 189 / 50%);
  }
`;

export const StyledButtonLight = styled.button`
  font-family: ${(props) => props.theme.fonts.text};
  font-weight: 600;
  font-size: 18px;
  background-color: ${(props) => props.theme.color.background_white};
  color: ${(props) => props.theme.color.text_dark};
  border: ${(props) => props.theme.color.background_dark};
  border-radius: 25px;
  padding: 16px 40px;
  text-transform: uppercase;
  border-width: 1px;
  border-style: solid;
  width: 100%;
`;

export const StyledLink = styled.a`
  text-transform: uppercase;
  text-decoration: underline;
  color: ${(props) => props.theme.color.text_light};
`;

export const StyledTextInput = styled.input`
  appearance: none;
  margin-bottom: 15px;
  width: 100%;
  padding: 10px 5px;
  border: 1px solid #8a8a8a;
  color: #000;
  background: ${(props) => (props.error ? "#ffe4c2" : "#fff")};
  text-align: left;
  :-webkit-autofill {
    box-shadow: ${(props) =>
      props.error ? "0 0 0 30px #ffe4c2 inset" : "0 0 0 30px #fff inset"};
    -webkit-text-fill-color: #000;
  }
  /* color: rgba(0, 0, 0, 0.5); */
  margin: 0 auto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  display: block;
  background: ${(props) => (props.error ? "#ff8787" : "#fff")};
  border-radius: 56px;
  width: 100%;
  height: 40px;
  border: 1px solid;
  border-color: grey;
  text-align: left;
  padding-left: 20px;
`;

export const StyledText = styled.div`
  color: ${(props) => props.theme.color.text_dark};
  font-family: ${(props) => props.theme.fonts.text};
  font-weight: 400;
  font-size: 18px;
  text-align: ${(props) => (props.centered ? "center" : "left")};
  line-height: 1.5;
`;

export const StyledTitel = styled.div`
  color: ${(props) => props.theme.color.text_dark};
  font-family: ${(props) => props.theme.fonts.text};
  font-size: 28px;
  line-height: 28px;
  font-weight: 700;
  text-align: ${(props) => (props.centered ? "center" : "left")};
`;

export const StyledSmallText = styled(StyledText)`
  font-size: 14px;
`;

export const StyledSelect = styled.select`
  color: rgba(0, 0, 0, 0.5);
  margin: 0 auto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.02em;
  display: block;
  background: ${(props) => (props.error ? "#ff8787" : "#fff")};
  border-radius: 56px;
  border: 1px solid #8a8a8a;
  width: 100%;
  height: 40px;
  text-align: left;
  padding-left: 20px;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
`;
