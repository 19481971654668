// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
/* import 'swiper/css/navigation'; */
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import "./styles.css";
import useTranslation from "lib/hooks/useTranslation";

const bilder = [
  "/images/slider/Emmi_EnergyMilk_High Protein_Banane Nougat_330ml Flasche_FRONTAL 2 (1).png",
  "/images/slider/Emmi_EnergyMilk_High Protein_Banane Nougat_330ml Flasche_FRONTAL 2 (2).png",
  "/images/slider/Emmi_EnergyMilk_High Protein_Banane Nougat_330ml Flasche_FRONTAL 2 (3).png",
  "/images/slider/Emmi_EnergyMilk_High Protein_Banane Nougat_330ml Flasche_FRONTAL 2 (4).png",
  "/images/slider/Emmi_EnergyMilk_High Protein_Banane Nougat_330ml Flasche_FRONTAL 2 (5).png",
  "/images/slider/Emmi_EnergyMilk_High Protein_Banane Nougat_330ml Flasche_FRONTAL 2 (6).png",
  "/images/slider/Emmi_EnergyMilk_High Protein_Banane Nougat_330ml Flasche_FRONTAL 2 (7).png",
  "/images/slider/Emmi_EnergyMilk_High Protein_Banane Nougat_330ml Flasche_FRONTAL 2 (8).png",
  "/images/slider/Emmi_EnergyMilk_High Protein_Banane Nougat_330ml Flasche_FRONTAL 2.png",
];

const Slider = () => {

    const handleClick = () => {
      window.open(
        language === "de"
          ? "https://www.emmi-energy-milk.ch/che/de/Produkte/Drinks"
          : "https://www.emmi-energy-milk.ch/che/fr/Produits/Drinks",
        "_blank"
      );
  };
  
  const { t, language } = useTranslation();


  return (
    <>
      <div className="text-3xl font-extrabold italic mt-20 px-8 mb-14 uppercase  md:text-[48px] md:leading-[56px]">
        {t("Kennst_du_schon")}
      </div>
      <div className="swiper-container pb-6 relative">
        <div className="image-swiper-button-prev absolute top-[40%] left-0 ml-8">
          <img src="/images/slider/Icon_left.svg" alt="" />
        </div>
        <div className="image-swiper-button-next absolute top-[40%] right-0 mr-8">
          <img src="/images/slider/Icon_right.svg" alt="" />
        </div>

        <Swiper
          // install Swiper modules
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={window.innerWidth < 768 ? 20 : 60}
          slidesPerView={window.innerWidth < 768 ? 3 : 4}
          navigation={{
            nextEl: ".image-swiper-button-next",
            prevEl: ".image-swiper-button-prev",
            disabledClass: "swiper-button-disabled",
          }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
        >
          {bilder.map((bild, index) => {
            return (
              <SwiperSlide>
                <img
                  className="leading-none block align-middle"
                  src={bild}
                  alt=""
                ></img>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      <div className="text-3xl font-extrabold italic mt-6 px-8 mb-14 uppercase  md:text-[48px] md:leading-[56px]">
        <button
          className="italic font-extrabold uppercase text-lg text-[#000]  bg-[#FFED00] px-8 py-4"
          onClick={handleClick}
        >
          {t("startseite_btn_hier_entdecken")}
        </button>
      </div>
    </>
  );
};

export default Slider;
