import { useAppState } from "lib/hooks/useAppState";
import React from "react";

const Progres = () => {
  const { state } = useAppState();

  const Nummer = ({ zahl, aktiv }) => {
    return (
      <div
        className={`${
          aktiv ? "bg-[#ffed00]" : "bg-white"
        }  italic text-lg rounded-full w-[36px] h-[36px] text-black flex justify-center items-center font-extrabold`}
      >
        <div>{zahl}</div>
      </div>
    );
  };

  return (
    <div className="px-8 mt-14 pb-10 relative">
      <div className="px-8 h-1 bg-white"></div>
      <div className="flex justify-around -mt-5">
        <Nummer zahl={1} aktiv={state.aktive_frage === 1}></Nummer>
        <Nummer zahl={2} aktiv={state.aktive_frage === 2}></Nummer>
        <Nummer zahl={3} aktiv={state.aktive_frage === 3}></Nummer>
{/*         <Nummer zahl={4} aktiv={state.aktive_frage === 4}></Nummer>
        <Nummer zahl={5} aktiv={state.aktive_frage === 5}></Nummer> */}
      </div>
    </div>
  );
};

export default Progres;
