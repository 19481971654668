import { PATTERN_EMAIL } from "helper/constants";
import { SCREENS } from "lib/constants";
import { readData, storeEndeData } from "lib/fetchData";
import useApiURL from "lib/hooks/useApiURL";
import { ACTIONS, useAppState } from "lib/hooks/useAppState";
import {
  useFormularState,
  ACTIONS as FORMACTIONS,
} from "lib/hooks/useFormularState";
import useTranslation from "lib/hooks/useTranslation";
import React, { useState } from "react";

const Endverlosung = () => {
  const {t, language } = useTranslation();
  const [form, setForm ] = useState({ email: "" , language: language});
  const [fehler, setFehler] = useState("");
  const [showButton, setShowButton] = useState(true);

  const { base_url } = useApiURL();

  const validate = () => {
    if (form.email === "") {
      setFehler(t("formular_fehler_email"));
      return false;
    }
    if (!PATTERN_EMAIL.test(form.email)) {
      setFehler(t("formular_fehler_email"));
      return false;
    }

    return true;
  };

  const handleClick = async () => {
    // validate
    if (validate()) {
      const data = {
        ...form,
      };
      const response = await storeEndeData(data, base_url);
      console.log("storeEndeData Response ", response);
      if (response.success === 1) {
        setShowButton(false)
      }
    }
  };

  const handleChange = (event) => {
    event.preventDefault();
    setFehler("");
    setForm({ ...form, email: event.target.value });
  };

  return (
    <>
      <div className="text-3xl leading-10 md:text-[56px] md:leading-[67px] font-extrabold italic mt-8 md:mt-24 px-8">
        {t("end_screen")}
      </div>
      {/* <div
        dangerouslySetInnerHTML={{ __html: t("end_screen_chance") }}
        className="text-lg italic font-extrabold leading-7 md:text-[22px] md:leading-8 px-8 mt-4 md:mt-8"
      ></div>
      <div
        dangerouslySetInnerHTML={{ __html: t("end_screen_formular") }}
        className="text-lg leading-7 md:text-[22px] md:leading-8 px-8 mt-4 md:mt-8"
      ></div>
      <div className="px-8">
        <input
          type="email"
          onChange={handleChange}
          name="email"
          value={form.email}
          placeholder="*E-Mail"
          className="bg-white mx-auto block md:inline form-input px-4 py-6 rounded-md w-full md:w-8/12 text-left mt-4 text-[#000] text-base font-normal border-none focus:border-transparent focus:ring-0 md:mt-8"
        ></input>
      </div>
      <div className="text-lg leading-7 px-8 mt-4">
        {t("startseite_pflichtfeld")}
      </div>
      {fehler !== "" && (
        <div className="text-lg leading-7 px-8 mt-4 text-yellow-300">
          {fehler}
        </div>
      )}
      {showButton ? (
        <div className="px-8 mt-8">
          <button
            className="italic font-extrabold uppercase text-lg text-[#000] bg-[#FFED00] px-8 py-4"
            onClick={handleClick}
          >
            {t("end_screen_btn")}
          </button>
        </div>
      ) : (
        <div
          dangerouslySetInnerHTML={{ __html: t("end_screen_danke") }}
          className="text-lg italic font-extrabold leading-7 md:text-[22px] md:leading-8 px-8 mt-4 md:mt-8"
        ></div>
      )} */}
    </>
  );
};

export default Endverlosung;
