// DAS KOMMT DANN IN EIN CONFIG FILE !!!!!
// Steuert welche ids verwendet werden
export const MODE = "prod";

// Die Ids sind für prod und test unterschiedlich
// Und auch für die Sprachen !!!!!!!!!!!!!!!!!!!!!!!!!!
export const SAP_IDS = [
  {
    name: "F1_A",
    test_id: {
      de: "",
      fr: "",
    },
    prod_id: {
      de: "__box20",
      fr: "__box92",
    },
    typ: "checkbox",
  },
  {
    name: "F1_B",
    test_id: {
      de: "",
      fr: "",
    },
    prod_id: {
      de: "__box21",
      fr: "__box93",
    },
    typ: "checkbox",
  },
  {
    name: "F1_C",
    test_id: {
      de: "",
      fr: "",
    },
    prod_id: {
      de: "__box22",
      fr: "__box94",
    },
    typ: "checkbox",
  },
  {
    name: "F1_D",
    test_id: {
      de: "",
      fr: "",
    },
    prod_id: {
      de: "__box23",
      fr: "__box95",
    },
    typ: "checkbox",
  },
  {
    name: "F2_B",
    test_id: {
      de: "",
      fr: "",
    },
    prod_id: {
      de: "__box24",
      fr: "__box96",
    },
    typ: "checkbox",
  },
  {
    name: "F2_C",
    test_id: {
      de: "",
      fr: "",
    },
    prod_id: {
      de: "__box25",
      fr: "__box97",
    },
    typ: "checkbox",
  },
  {
    name: "F2_D",
    test_id: {
      de: "",
      fr: "",
    },
    prod_id: {
      de: "__box26",
      fr: "__box98",
    },
    typ: "checkbox",
  },
  {
    name: "F3_A_SEHR_WICHTIG",
    test_id: {
      de: "",
      fr: "",
    },
    prod_id: {
      de: "__box27",
      fr: "__box99",
    },
    typ: "checkbox",
  },
  {
    name: "F3_A_WICHTIG",
    test_id: {
      de: "",
      fr: "",
    },
    prod_id: {
      de: "__box28",
      fr: "__box100",
    },
    typ: "checkbox",
  },
  {
    name: "F3_B_SEHR_WICHTIG",
    test_id: {
      de: "",
      fr: "",
    },
    prod_id: {
      de: "__box29",
      fr: "__box101",
    },
    typ: "checkbox",
  },
  {
    name: "F3_B_WICHTIG",
    test_id: {
      de: "",
      fr: "",
    },
    prod_id: {
      de: "__box30",
      fr: "__box102",
    },
    typ: "checkbox",
  },
  {
    name: "F3_C_SEHR_WICHTIG",
    test_id: {
      de: "",
      fr: "",
    },
    prod_id: {
      de: "__box31",
      fr: "__box103",
    },
    typ: "checkbox",
  },
  {
    name: "F3_C_WICHTIG",
    test_id: {
      de: "",
      fr: "",
    },
    prod_id: {
      de: "__box32",
      fr: "__box104",
    },
    typ: "checkbox",
  },
  {
    name: "F4_A_SEHR_WAHRSCHEINLICH",
    test_id: {
      de: "",
      fr: "",
    },
    prod_id: {
      de: "__box33",
      fr: "__box105",
    },
    typ: "checkbox",
  },
  {
    name: "F4_A_WAHRSCHEINLICH",
    test_id: {
      de: "",
      fr: "",
    },
    prod_id: {
      de: "__box34",
      fr: "__box106",
    },
    typ: "checkbox",
  },
  {
    name: "gewonnen",
    test_id: {
      de: "",
      fr: "",
    },
    prod_id: {
      de: "__box36",
      fr: "__box108",
    },
    typ: "checkbox",
  },
  {
    name: "anrede",
    test_id: {
      de: "",
      fr: "",
    },
    prod_id: {
      de: "__down5",
      fr: "__down25",
    },
    typ: "normal",
  },
  {
    name: "vorname",
    test_id: {
      de: "",
      fr: "",
    },
    prod_id: {
      de: "__input6",
      fr: "__input26",
    },
    typ: "normal",
  },
  {
    name: "land",
    test_id: {
      de: "",
      fr: "",
    },
    prod_id: {
      de: "__down6",
      fr: "__down26",
    },
    typ: "normal",
  },
  {
    name: "tag",
    test_id: {
      de: "",
      fr: "",
    },
    prod_id: {
      de: "__down7",
      fr: "__down27",
    },
    typ: "normal",
  },
  {
    name: "monat",
    test_id: {
      de: "",
      fr: "",
    },
    prod_id: {
      de: "__down8",
      fr: "__down28",
    },
    typ: "normal",
  },
  {
    name: "jahr",
    test_id: {
      de: "",
      fr: "",
    },
    prod_id: {
      de: "__down9",
      fr: "__down29",
    },
    typ: "normal",
  },
  {
    name: "email",
    test_id: {
      de: "",
      fr: "",
    },
    prod_id: {
      de: "__input9",
      fr: "__input29",
    },
    typ: "normal",
  },
  {
    name: "teilnahmebedingungen",
    test_id: {
      de: "",
      fr: "",
    },
    prod_id: {
      de: "__box37",
      fr: "__box109",
    },
    typ: "checkbox",
  },
];

export const SAP_BUTTON_ID = {
  test_id: {
    de: "",
    fr: "",
  },
  prod_id: {
    de: "__button19",
    fr: "__button31",
  },
};

// Die RegEx Pattern für die Validierung des Formulars
export const PATTERN_EMAIL = new RegExp(
  // eslint-disable-next-line
  /* /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ */
  /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/
);

/* export const PATTERN_TELEFON = new RegExp("^\\+(?:[0-9] ?){6,28}[0-9]$"); */
export const PATTERN_TELEFON = new RegExp("^\\+[1-9]{1}[0-9]{6,28}$");

export const PATTERN_PLZ = new RegExp(/^[0-9]{4}$/);
