import styled from "styled-components";

const GenericText = styled.div`
  text-align: ${(props) => (props.center ? "center" : "left")};
`;

export const StyledParagraph = styled(GenericText)`
  font-family: ${(props) => props.theme.font_text};
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  @media (min-width: ${(props) => props.theme.breakPoints.md}) {
  }
`;

export const StyledH1 = styled(GenericText)`
  font-family: ${(props) => props.theme.font_titel};
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  @media (min-width: ${(props) => props.theme.breakPoints.md}) {
  }
`;

export const StyledH2 = styled(GenericText)`
  font-family: ${(props) => props.theme.font_titel};
  font-style: normal;
  font-weight: 800;
  font-size: 34px;
  line-height: 40px;
  @media (min-width: ${(props) => props.theme.breakPoints.md}) {
  }
`;

export const StyledH3 = styled(GenericText)`
  font-family: ${(props) => props.theme.font_titel};
  font-style: normal;
  font-weight: 800;
  font-size: 26px;
  line-height: 32px;
  letter-spacing: 0.02em;
  padding: 0 16px;
  @media (min-width: ${(props) => props.theme.breakPoints.md}) {
  }
`;

export const StyledLead = styled(GenericText)`
  font-family: ${(props) => props.theme.font_text};
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 28px;
  @media (min-width: ${(props) => props.theme.breakPoints.md}) {
  }
`;

export const StyledError = styled(StyledLead)`
  color: #ff0000;
`;

export const StyledLink = styled(GenericText)`
  font-family: ${(props) => props.theme.font_text};
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  text-decoration-line: underline;
  @media (min-width: ${(props) => props.theme.breakPoints.md}) {
  }
`;

export const StyledSmall = styled(GenericText)`
  font-family: ${(props) => props.theme.font_text};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  @media (min-width: ${(props) => props.theme.breakPoints.md}) {
  }
`;

export const StyledButton = styled(GenericText)`
  font-family: ${(props) => props.theme.font_titel};
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.04em;
  @media (min-width: ${(props) => props.theme.breakPoints.md}) {
  }
`;

export const DickerStrich = styled.div`
  width: 120%;
  margin-left: -10%;
  height: 21px;
  background-color: #fae000;
  margin-top: -22px;
  @media (min-width: ${(props) => props.theme.breakPoints.md}) {
  }
`;
