const useApiURL = () => {
  const API_URL = {
    URL_LOCALE: "http://eem-new-year-2024/",
    URL_REMOTE: "https://energymilk.ch/php/",
  };

  let base_url = API_URL.URL_REMOTE;
  if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
  ) {
    base_url = API_URL.URL_LOCALE;
  }

  return { base_url };
};

export default useApiURL;
