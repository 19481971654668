import useApiURL from "lib/hooks/useApiURL";
import { ACTIONS, useAppState } from "lib/hooks/useAppState";
import ComingSoon from "pages/ComingSoon";
import Home from "pages/Home";
import Startseite from "pages/Startseite";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

function App() {
  const baseUrl = process.env.PUBLIC_URL;
  const { dispatch } = useAppState();
  const { base_url } = useApiURL();

  useEffect(() => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const hasP = params.has("p");
    const p = params.get("p");
    dispatch({
      type: ACTIONS.SET_PRODUKT,
      payload: hasP ? p.toUpperCase() : "NULL",
    });
    dispatch({
      type: ACTIONS.SET_PRODUKT_MIT_SIZE,
      payload: hasP ? p.toUpperCase() : "NULL",
    });
  }, [dispatch]);

  return (
    <div>
      <Routes>
{/*         <Route
          path={baseUrl + "/preview/de"}
          element={<Startseite language="de"></Startseite>}
        />
        <Route
          path={baseUrl + "/preview/fr"}
          element={<Startseite language="fr"></Startseite>}
        /> */}
        <Route path={baseUrl + "/de"} element={<Home language="de"></Home>} />
        <Route path={baseUrl + "/fr"} element={<Home language="fr"></Home>} />
        <Route path={baseUrl + "/"} element={<Home language="de"></Home>} />
        <Route path="*" element={<Startseite language="de"></Startseite>} />
      </Routes>
    </div>
  );
}

export default App;
