/* eslint-disable react/jsx-pascal-case */
import { fillSAPform } from "helper/fillSAPform";
import { SCREENS } from "lib/constants";
import { ACTIONS, useAppState } from "lib/hooks/useAppState";
import {
  useFormularState,
  ACTIONS as FORMACTIONS,
} from "lib/hooks/useFormularState";
import useTranslation from "lib/hooks/useTranslation";
import React, { useEffect, useState } from "react";
import Frage_1 from "./Frage_1";
import Frage_2 from "./Frage_2";
import Frage_3 from "./Frage_3";
/* import Frage_4 from "./Frage_4"; */
import Frage_5 from "./Frage_5";
import Progres from "./Progres";
import { MODE, SAP_BUTTON_ID, SAP_IDS } from "helper/constants";
import { createData } from "lib/fetchData";
import useApiURL from "lib/hooks/useApiURL";

const Fragen = () => {
  const { t, language } = useTranslation();
  const { state, dispatch } = useAppState();
  const { formDispatch, formState } = useFormularState();
  const [fehler, setFehler] = useState("");
  const [error, setError] = useState("");
  const { base_url } = useApiURL();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  const validate = () => {
    let ok = false;
    switch (state.aktive_frage) {
      case 1:
        ok =
          formState.F1_A ||
          formState.F1_B ||
          formState.F1_C ||
          formState.F1_D ||
          formState.F1_E;
        !ok && setFehler(t("frage_1_fehler"));
        return ok;
      case 2:
        ok =
          formState.F2_A ||
          formState.F2_B ||
          formState.F2_C ||
          formState.F2_D ||
          formState.F2_E;
        !ok && setFehler(t("frage_2_fehler"));
        return ok;
      case 3:
        ok =
          formState.F3_A ||
          formState.F3_B ||
          formState.F3_C ||
          formState.F3_D ||
          formState.F3_E;
        !ok && setFehler(t("frage_3_fehler"));
        return ok;
      /*       case 3:
        ok =
          formState.F3_A_TOUCHED &&
          formState.F3_B_TOUCHED &&
          formState.F3_C_TOUCHED;
        !ok && setFehler(t("frage_3_fehler"));
        return ok; */
      case 4:
/*         ok = formState.F4_A_TOUCHED;
        !ok && setFehler(t("frage_4_fehler"));
        return ok; */
        return true;
      case 5:
        if (formState.anrede === "") {
          setFehler(t("formular_fehler"));
          setError("anrede");
          return false;
        }
        if (formState.vorname === "") {
          setFehler(t("formular_fehler"));
          setError("vorname");
          return false;
        }
        if (formState.nachname === "") {
          setFehler(t("formular_fehler"));
          setError("nachname");
          return false;
        }
        if (formState.tag === "") {
          setFehler(t("formular_fehler"));
          setError("tag");
          return false;
        }
        if (formState.monat === "") {
          setFehler(t("formular_fehler"));
          setError("monat");
          return false;
        }
        if (formState.jahr === "") {
          setFehler(t("formular_fehler"));
          setError("jahr");
          return false;
        }
        if (formState.wohnhaft === false) {
          setFehler(t("formular_fehler"));
          setError("wohnhaft");
          return false;
        }
        if (formState.teilnahmebedingungen === false) {
          setFehler(t("formular_fehler"));
          setError("teilnahmebedingungen");
          return false;
        }
        break;
      default:
        break;
    }

    // Alterscheck
    const dob = formState.jahr + formState.monat + formState.tag;
    const year = Number(dob.substr(0, 4));
    const month = Number(dob.substr(4, 2)) - 1;
    const day = Number(dob.substr(6, 2));
    const today = new Date();
    let age = today.getFullYear() - year;
    if (
      today.getMonth() < month ||
      (today.getMonth() === month && today.getDate() < day)
    ) {
      age--;
    }

    if (parseInt(age) < 18) {
      setFehler(t("Alter.err"));
      return false;
    }

    return true;
  };

  const handleClickZurueck = () => {
    setFehler("");
    if (state.aktive_frage === 1) {
      dispatch({
        type: ACTIONS.SET_SCREEN,
        payload: SCREENS.STARTSEITE,
      });
      return;
    }
    dispatch({
      type: ACTIONS.SET_AKTIVE_FRAGE,
      payload: state.aktive_frage - 1,
    });
  };

/*   const updateStateForSAP = () => {
    if (formState.F3_A >= 60 && formState.F3_A < 80) {
      formDispatch({
        type: FORMACTIONS.SET_DATA,
        payload: {
          key: "F3_A_WICHTIG",
          value: true,
        },
      });
    } else {
      formDispatch({
        type: FORMACTIONS.SET_DATA,
        payload: {
          key: "F3_A_WICHTIG",
          value: false,
        },
      });
    }
    if (formState.F3_A >= 80) {
      formDispatch({
        type: FORMACTIONS.SET_DATA,
        payload: {
          key: "F3_A_SEHR_WICHTIG",
          value: true,
        },
      });
    } else {
      formDispatch({
        type: FORMACTIONS.SET_DATA,
        payload: {
          key: "F3_A_SEHR_WICHTIG",
          value: false,
        },
      });
    }

    if (formState.F3_B >= 60 && formState.F3_B < 80) {
      formDispatch({
        type: FORMACTIONS.SET_DATA,
        payload: {
          key: "F3_B_WICHTIG",
          value: true,
        },
      });
    } else {
      formDispatch({
        type: FORMACTIONS.SET_DATA,
        payload: {
          key: "F3_B_WICHTIG",
          value: false,
        },
      });
    }
    if (formState.F3_B >= 80) {
      formDispatch({
        type: FORMACTIONS.SET_DATA,
        payload: {
          key: "F3_B_SEHR_WICHTIG",
          value: true,
        },
      });
    } else {
      formDispatch({
        type: FORMACTIONS.SET_DATA,
        payload: {
          key: "F3_B_SEHR_WICHTIG",
          value: false,
        },
      });
    }

    if (formState.F3_C >= 60 && formState.F3_C < 80) {
      formDispatch({
        type: FORMACTIONS.SET_DATA,
        payload: {
          key: "F3_C_WICHTIG",
          value: true,
        },
      });
    } else {
      formDispatch({
        type: FORMACTIONS.SET_DATA,
        payload: {
          key: "F3_C_WICHTIG",
          value: false,
        },
      });
    }
    if (formState.F3_C >= 80) {
      formDispatch({
        type: FORMACTIONS.SET_DATA,
        payload: {
          key: "F3_C_SEHR_WICHTIG",
          value: true,
        },
      });
    } else {
      formDispatch({
        type: FORMACTIONS.SET_DATA,
        payload: {
          key: "F3_C_SEHR_WICHTIG",
          value: false,
        },
      });
    }

    if (formState.F4_A >= 60 && formState.F4_A < 80) {
      formDispatch({
        type: FORMACTIONS.SET_DATA,
        payload: {
          key: "F4_A_WAHRSCHEINLICH",
          value: true,
        },
      });
    } else {
      formDispatch({
        type: FORMACTIONS.SET_DATA,
        payload: {
          key: "F4_A_WAHRSCHEINLICH",
          value: false,
        },
      });
    }
    if (formState.F4_A >= 80) {
      formDispatch({
        type: FORMACTIONS.SET_DATA,
        payload: {
          key: "F4_A_SEHR_WAHRSCHEINLICH",
          value: true,
        },
      });
    } else {
      formDispatch({
        type: FORMACTIONS.SET_DATA,
        payload: {
          key: "F4_A_SEHR_WAHRSCHEINLICH",
          value: false,
        },
      });
    }
  }; */

  const handleClickWeiter = async () => {
    if (!validate()) return;
    // Daten für den Versand an SAP erstellen.
    //updateStateForSAP();
    // Wenn die aktive Frage 3 ist, dann weiter zu Gewinn oder Sorry Seite
    if (state.aktive_frage === 3) {
      // Create user
      const data = {
        ...formState,
        email_sofortpreis_ochsner: t("email_sofortpreis_ochsner"),
        email_sofortpreis_yogamatte: t("email_sofortpreis_yogamatte"),
        email_sofortpreis_exerbell: t("email_sofortpreis_exerbell"),
        email_sofortpreis_flasche: t("email_sofortpreis_flasche"),
        email_sofortpreis_microfasertuch: t("email_sofortpreis_microfasertuch"),
        email_sofortpreis_tasche: t("email_sofortpreis_tasche"),
        language,
        token: state.token,
      };
      const response = await createData(data, base_url);
      console.log("createData Response ", response);
/*       fillSAPform(
        MODE,
        { ...formState, gewonnen: response.payload.typ !== "WETTBEWERB" },
        SAP_IDS,
        language
      );
      document.getElementById(SAP_BUTTON_ID[MODE + "_id"][language]).click(); */

      // Google Data Layer
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "submit",
      });

      formDispatch({
        type: FORMACTIONS.SET_DATA,
        payload: {
          key: "id",
          value: response.payload.id,
        },
      });
      formDispatch({
        type: FORMACTIONS.SET_DATA,
        payload: {
          key: "preis_id",
          value: response.payload.preis_id,
        },
      });
      formDispatch({
        type: FORMACTIONS.SET_DATA,
        payload: {
          key: "typ",
          value: response.payload.typ,
        },
      });
      // Wenn es KEIN Sofortpreis ist, also nur Wettbewerb Teilnahme:
      if (response.payload.typ === "WETTBEWERB") {
        dispatch({
          type: ACTIONS.SET_SCREEN,
          payload: SCREENS.SCHADE,
        });
      } else {
        // Wenn es ein Sofortpreis ist:
        dispatch({
          type: ACTIONS.SET_SCREEN,
          payload: SCREENS.GEWINN,
        });
      }
      return;
    }
    dispatch({
      type: ACTIONS.SET_AKTIVE_FRAGE,
      payload: state.aktive_frage + 1,
    });
  };

  return (
    <div className="pt-[71px] pb-20">
      <div className="text-white text-3xl font-extrabold italic px-8 mt-10  md:text-[48px] md:leading-[56px]">
        {t("fragen_titel")}
      </div>
      <Progres></Progres>
      {state.aktive_frage === 1 && <Frage_1 setFehler={setFehler}></Frage_1>}
      {state.aktive_frage === 2 && <Frage_2 setFehler={setFehler}></Frage_2>}
      {state.aktive_frage === 3 && <Frage_3 setFehler={setFehler}></Frage_3>}
      {/* {state.aktive_frage === 4 && <Frage_4 setFehler={setFehler}></Frage_4>} */}
      {state.aktive_frage === 5 && (
        <Frage_5
          setFehler={setFehler}
          error={error}
          setError={setError}
        ></Frage_5>
      )}

      {fehler !== "" && (
        <div className="text-lg leading-7 px-8 mt-4 text-yellow-300">
          {fehler}
        </div>
      )}
      <div className="flex px-8 mt-10">
        <button
          className="border-white w-1/2 border italic font-extrabold uppercase text-lg text-[#fff] bg-[#000] px-8 py-4"
          onClick={handleClickZurueck}
        >
          {t("fragen_zurueck_btn")}
        </button>

        <button
          className="italic w-1/2 font-extrabold uppercase text-lg text-[#000] bg-[#FFED00] px-8 py-4 ml-8"
          onClick={handleClickWeiter}
        >
          {state.aktive_frage === 3
            ? t("fragen_teilnehmen_btn")
            : t("fragen_weiter_btn")}
        </button>
      </div>
    </div>
  );
};

export default Fragen;
