import React from "react";
import useTranslation from "lib/hooks/useTranslation";
import fb from "./fb.png";
import insta from "./insta.png";
import tiktok from "./tiktok.png";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="pb-20">
      <div className="w-full bg-white h-[1px]"></div>
      <div className="text-lg italic font-extrabold leading-10 px-8  mt-10 cursor-pointer lg:flex lg:justify-between">
        <div>
          <div className="md:flex">
            <div
              className=""
              onClick={() => {
                window.open(t("footer_datenschutz_link"));
              }}
            >
              {t("footer_datenschutz")}
            </div>
            <div
              className="md:ml-8"
              onClick={() => {
                window.open(t("footer_tb_link"));
              }}
            >
              {t("footer_tb")}
            </div>
            <div
              className="md:ml-8"
              onClick={() => {
                window.open(t("footer_nutzungsbedingungen_link"));
              }}
            >
              {t("footer_nutzungsbedingungen")}
            </div>
            <div
              className="md:ml-8"
              onClick={() => {
                window.open(t("footer_kontakt_link"));
              }}
            >
              {t("footer_kontakt")}
            </div>
            <div
              className="md:ml-8"
              onClick={() => {
                window.open(t("footer_website_link"));
              }}
            >
              {t("footer_website")}
            </div>
          </div>
        </div>
        <div className="flex mt-4">
          <img
            className="w-[40px] h-[40px]"
            src={fb}
            alt=""
            onClick={() => {
              window.open(t("footer_fb_link"));
            }}
          ></img>
          <img
            className="w-[40px] h-[40px] ml-6"
            src={insta}
            alt=""
            onClick={() => {
              window.open(t("footer_insta_link"));
            }}
          ></img>
          <img
            className="w-[40px] h-[40px] ml-6"
            src={tiktok}
            alt=""
            onClick={() => {
              window.open(t("footer_tiktok_link"));
            }}
          ></img>
        </div>
      </div>
    </div>
  );
};

export default Footer;
