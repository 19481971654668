import React from "react";
import styled from "styled-components";

const Label = styled.label`
  display: block;
  text-transform: uppercase;
  color: white;
  font-family: futura-pt, sans-serif;
  font-size: 14px;
  font-weight: 800;
  font-style: italic;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin-bottom: 3px;

  @media (min-width: 750px) {
    margin-bottom: 6px;
    font-size: 26px;
  }
`;

const SelectWrapper = styled.div`
  margin-bottom: 16px;
  position: relative;
`;

const Select = styled.select`
  font-family: "Assistant";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  padding: 8px 16px;
  background-color: ${(props) => (props.error ? "#ff9595" : "#eee")};
  color: #222;
  width: 100%;
  -webkit-appearance: none;

  &:focus {
    outline: none;
  }
`;

const Icon = styled.div`
  display: flex;
  color: #2d3748;
  color: rgba(45, 55, 72);
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  pointer-events: none;
  padding-left: 8px;
  padding-right: 8px;
  align-items: center;
`;

const SVG = styled.svg`
  width: 1rem;
  fill: currentColor;
  height: 1rem;
`;

export const SelectInput = ({
  error,
  name,
  value,
  onChange,
  options,
  label,
  placeholder,
  onFocus,
}) => {
  return (
    <div>
      <Label>{label}</Label>
      <SelectWrapper>
        <Select
          name={name}
          value={value}
          onChange={onChange}
          error={error}
          onFocus={onFocus}
        >
          <option value="" disabled>
            {placeholder}
          </option>
          {options.map((item) => {
            return (
              <option key={item.key} value={item.value}>
                {item.text}
              </option>
            );
          })}
        </Select>
        <Icon>
          <SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </SVG>
        </Icon>
      </SelectWrapper>
    </div>
  );
};
