import useTranslation from "lib/hooks/useTranslation";
import React from "react";

const SpotifyTeaser = () => {
  const handleClickMotivation = () => {
    window.open(
      "https://open.spotify.com/playlist/37i9dQZF1DXdxcBWuJkbcy?si=5affc61b47b34601&nd=1&dlsi=151ce60dd8484b7c",
      "_blank"
    );
  };
    const handleClickBeast = () => {
    window.open(
      "https://open.spotify.com/playlist/37i9dQZF1DX76Wlfdnj7AP?si=a8b34abe48e54e95&nd=1&dlsi=576791b66c0f46dc",
      "_blank"
    );
  };


  const { t, language } = useTranslation();

  return (
    <>
      {language === "de" ? (
        <>
          <div className="hidden md:block">
            <div className="grid grid-flow-col grid-cols-[55%_45%]  mx-4">
              <div className="flex flex-col justify-center bg-[#1ED760]">
                <div
                  dangerouslySetInnerHTML={{
                    __html: t("spotify_text"),
                  }}
                  className="text-white bg-[#1ED760] text-3xl md:text-[26px] md:leading-[36px] font-extrabold italic mt-8 px-8 md:px-10"
                ></div>
                <div className="px-8 mt-6 pb-8 bg-[#1ED760]">
                  <button
                    className="italic font-extrabold uppercase text-lg text-[#fff] bg-[#000] px-8 py-4"
                    onClick={handleClickMotivation}
                  >
                    Motivation Mix
                  </button>
                  <br />
                  <button
                    className="italic font-extrabold uppercase text-lg text-[#fff] bg-[#000] px-8 py-4 mt-3"
                    onClick={handleClickBeast}
                  >
                    Beast Mode
                  </button>
                </div>
              </div>
              <div className="bg-white flex items-center">
                <img
                  className=""
                  src={`/images/spotify/img.jpg`}
                  alt=""
                ></img>
              </div>
            </div>
          </div>

          <div className="md:w-4/12 bg-[#1ED760] mx-8 md:hidden">
            <img
              className="leading-none block align-middle"
              src={`/images/spotify/img.jpg`}
              alt=""
            ></img>
            <div className="text-black text-3xl md:text-[26px] md:leading-[36px] md:text-center font-extrabold italic mt-8 px-8 md:px-10">
              {t("spotify_text")}
            </div>
            <div className="px-8 mt-6 pb-8 bg-[#1ED760] text-center mx-auto">
              <button
                className="italic font-extrabold uppercase text-lg text-[#fff] bg-[#000] px-8 py-4 mx-auto"
                onClick={handleClickMotivation}
              >
                Motivation Mix
              </button>
              <button
                className="italic font-extrabold uppercase text-lg text-[#fff] bg-[#000] px-8 py-4 mt-3 mx-auto text-center"
                onClick={handleClickBeast}
              >
                Beast Mode
              </button>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default SpotifyTeaser;
