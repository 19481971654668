import React from "react";
import styled from "styled-components";
import insta from "./insta.png";
import fb from "./fb.png";
import tiktok from "./tiktok.png";
import useTranslation from "lib/hooks/useTranslation";
import { XSpacer, YSpacer } from "lib/responsive-spacer";
import { ACTIONS, useAppState } from "lib/hooks/useAppState";
import { SCREENS } from "lib/constants";
import { StyledH2 } from "styles/typo";

const Menue = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useAppState();

  const handleItem = (item) => {
    dispatch({
      type: ACTIONS.SET_OVERLAYMENUVISIBLE,
      payload: false,
    });
    dispatch({
      type: ACTIONS.SET_CLICKEDMENUITEM,
      payload: item,
    });
  };

  return (
    <MenuWrapper>
      <YSpacer mb="80" />
      <LinkWrapper>
        <MenuItem
          onClick={() => {
            handleItem(t("menu_gewinnspiel"));
            //Router.push("/");
            dispatch({
              type: ACTIONS.SET_AKTIVE_FRAGE,
              payload: 1,
            });
            dispatch({
              type: ACTIONS.SET_SCREEN,
              payload: SCREENS.STARTSEITE,
            });
          }}
        >
          {t("menu_gewinnspiel")}
        </MenuItem>
        <YSpacer mb="24" />
        <MenuItem
          onClick={() => {
            handleItem(t("menu_hauptpreise"));
            //Router.route !== "/" && Router.push("/");
            dispatch({
              type: ACTIONS.SET_AKTIVE_FRAGE,
              payload: 1,
            });
            dispatch({
              type: ACTIONS.SET_SCREEN,
              payload: SCREENS.STARTSEITE,
            });
          }}
        >
          {t("menu_hauptpreise")}
        </MenuItem>
        <YSpacer mb="24" />
        <MenuItem
          onClick={() => {
            handleItem(t("menu_sofortpreise"));
            //Router.route !== "/" && Router.push("/");
            dispatch({
              type: ACTIONS.SET_AKTIVE_FRAGE,
              payload: 1,
            });
            dispatch({
              type: ACTIONS.SET_SCREEN,
              payload: SCREENS.STARTSEITE,
            });
          }}
        >
          {t("menu_sofortpreise")}
        </MenuItem>
        <YSpacer mb="24" />
        <MenuItem
          onClick={() => {
            handleItem(t("menu_produkte"));
            //Router.route !== "/" && Router.push("/");
            dispatch({
              type: ACTIONS.SET_AKTIVE_FRAGE,
              payload: 1,
            });
            dispatch({
              type: ACTIONS.SET_SCREEN,
              payload: SCREENS.STARTSEITE,
            });
            window.open(t("menu_produkte_link"));
          }}
        >
          {t("menu_produkte")}
        </MenuItem>

        <YSpacer mb="30" />
      </LinkWrapper>
      <YSpacer mb="130" />
      <IconWrapper>
        <Icon
          onClick={() => {
            window.open(t("footer_fb_link"));
          }}
          src={fb}
          layout="fixed"
          width={30}
          height={30}
        ></Icon>
        <XSpacer mb={20}></XSpacer>
        <Icon
          onClick={() => {
            window.open(t("footer_insta_link"));
          }}
          src={insta}
          layout="fixed"
          width={30}
          height={30}
        ></Icon>
        <XSpacer mb={20}></XSpacer>
        {/*         <Icon
          onClick={() => {
            window.open(t("footer_youtube_link"));
          }}
          src={youtube}
          layout="fixed"
          width={30}
          height={30}
        ></Icon>
        <XSpacer mb={20}></XSpacer> */}
        <Icon
          onClick={() => {
            window.open(t("footer_tiktok_link"));
          }}
          src={tiktok}
          layout="fixed"
          width={30}
          height={30}
        ></Icon>
      </IconWrapper>
    </MenuWrapper>
  );
};

// Helper
const Icon = styled.img`
  cursor: pointer;
`;
const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const LinkWrapper = styled.div`
  padding-top: 15vh;
`;

const MenuWrapper = styled.div`
  top: 0;
  left: 0;
  text-align: center;
  background: #000;
  color: #fff;
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 3000;
  padding-bottom: 20px;
  overflow-y: scroll;

  @media (min-width: 1024px) {
    /* display: none; */
  }
`;

const MenuItem = styled(StyledH2)`
  padding: 0 10px;
  cursor: pointer;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: 0.02em;
  text-align: center;
  font-weight: 800;
  font-style: italic;
  text-transform: uppercase;
`;

const SprachLinksWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export default Menue;
