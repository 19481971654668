import { useState, createContext, useContext } from "react";
import texte from "locales/texte.json";

const data = [...texte];
const translationContext = createContext(null);

export const TranslationProvider = ({ children }) => {
  const [language, setLanguage] = useState("de");

  const t = (id) => {
    const found = data.filter((item) => {
      return item.id === id;
    });

    if (found.length) {
      return found[0][language];
    } else {
      return id;
    }
  };

  return (
    <translationContext.Provider value={{ t, language, setLanguage }}>
      {children}
    </translationContext.Provider>
  );
};

export default function useTranslation() {
  return useContext(translationContext);
}
