import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useTranslation from "lib/hooks/useTranslation";
import { YSpacer } from "lib/responsive-spacer";
import styled from "styled-components";
import { StyledH2, StyledParagraph } from "styles/typo";
/* import ps1 from "img/packshots/ECL_EU1_230_PS_Espresso2021_Campaign_CLEAN 1.png";
import ps2 from "img/packshots/ECL_EU1_230_PS_Espresso2021_Campaign_CLEAN 1-1.png"; */
import ps3 from "img/packshots/ECL_EU1_230_PS_Espresso2021_Campaign_CLEAN 1-2.png";
/* import ps4 from "img/packshots/ECL_EU1_230_PS_Espresso2021_Campaign_CLEAN 1-3.png";
import ps5 from "img/packshots/ECL_EU1_230_PS_Espresso2021_Campaign_CLEAN 1-4.png"; */
import ps6 from "img/packshots/ECL_EU1_230_PS_Espresso2021_Campaign_CLEAN 1-5.png";
import ps7 from "img/packshots/ECL_EU1_230_PS_Espresso2021_Campaign_CLEAN 1-6.png";
import ps8 from "img/packshots/ECL_EU1_230_PS_Espresso2021_Campaign_CLEAN 1-7.png";
import ps9 from "img/packshots/ECL_EU1_230_PS_Espresso2021_Campaign_CLEAN 1-8.png";
import ps10 from "img/packshots/ECL_EU1_230_PS_Espresso2021_Campaign_CLEAN 1-9.png";
import ps11 from "img/packshots/ECL_EU1_230_PS_Espresso2021_Campaign_CLEAN 1-10.png";
import ps12 from "img/packshots/ECL_EU1_230_PS_Espresso2021_Campaign_CLEAN 1-11.png";
import dz from "img/packshots/dz_wendy.png";
import dzm from "img/packshots/dzm_wendy.png";
import { StyledUIButton } from "styles/uielements";
import Slider from "react-slick";
import { useMediaQuery } from "react-responsive";

const Sortiment = () => {
  const { t } = useTranslation();
  const bilder = [dz, dzm, ps10, ps3, ps6, ps7, ps8, ps9, ps11, ps12];

  const handleClick = () => {
    window.open(t("emmiwebsite_link"), "_blank");
  };

  const isDesktop = useMediaQuery({
    query: "(min-width: 768px)",
  });

  const settings = {
    dots: true,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: isDesktop ? 5 : 3,
    slidesToScroll: isDesktop ? 5 : 3,
  };

  return (
    <>
      <StyledTeaser>
        <Container>
          <YSpacer mb={72} md={200}></YSpacer>
          <StyledH2 center>{t("sortiment_titel")}</StyledH2>
          <YSpacer mb={32}></YSpacer>
          <StyledParagraph center>{t("sortiment_text")}</StyledParagraph>
          <YSpacer mb={24}></YSpacer>
          {/*           <StyledImageContainer>
            <StyledImage src={sortiment} alt=""></StyledImage>
          </StyledImageContainer> */}
          <Slider {...settings}>
            {bilder.map((item) => {
              return (
                <div key={item}>
                  <Produktbild src={item} alt="" />
                </div>
              );
            })}
          </Slider>
          <YSpacer mb={48}></YSpacer>
          <StyledUIButton onClick={handleClick}>
            {t("sortiment_button")}
          </StyledUIButton>
          <YSpacer mb={48} md={170}></YSpacer>
        </Container>
      </StyledTeaser>
    </>
  );
};

const Produktbild = styled.img`
  width: 120px;
  margin: 0 auto;
  @media (min-width: ${(props) => props.theme.breakPoints.md}) {
    width: 150px;
  }
`;

const StyledTeaser = styled.div`
  background-color: #fff;
`;

const Container = styled.div`
  background-color: #fff;
  padding: 0 30px;
  margin: 0 auto;
  width: 100%;
  @media (min-width: ${(props) => props.theme.breakPoints.md}) {
    max-width: 768px;
    padding: 0;
  }
`;

export default Sortiment;
