export const storeEndeData = async (bodydata, base_url) => {
  const body = bodydata;
  console.log(body);
  const response = await fetch(base_url + "store_ende.php", {
    method: "POST",
    headers: {
      accept: "application/JSON",
    },
    body: JSON.stringify(body),
  });
  const response_data = await response.json();
  return response_data;
};

export const createData = async (bodydata, base_url) => {
  const body = bodydata;
  console.log(body);
  const response = await fetch(base_url + "store.php", {
    method: "POST",
    headers: {
      accept: "application/JSON",
    },
    body: JSON.stringify(body),
  });
  const response_data = await response.json();
  return response_data;
};

export const updateData = async (bodydata, base_url) => {
  const body = bodydata;
  console.log(body);
  const response = await fetch(base_url + "update.php", {
    method: "POST",
    headers: {
      accept: "application/JSON",
    },
    body: JSON.stringify(body),
  });
  const response_data = await response.json();
  return response_data;
};

export const readData = async (bodydata, base_url) => {
  const body = bodydata;
  console.log(body);
  const response = await fetch(base_url + "read.php", {
    method: "POST",
    headers: {
      accept: "application/JSON",
    },
    body: JSON.stringify(body),
  });
  const response_data = await response.json();
  return response_data;
};
