import useTranslation from "lib/hooks/useTranslation";
import React, { useEffect } from "react";

const Danke = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  return (
    <>
      <div className="text-3xl font-extrabold italic mt-8 px-8  md:text-[56px] md:leading-[67px]">
        {t("danke_angaben_titel")}
      </div>
      <div className="text-lg leading-7 mt-8 px-8 mb-12 md:mb-28 md:text-[22px] md:leading-8 md:mt-4">
        {t("danke_angaben_text")}
      </div>
    </>
  );
};

export default Danke;
