import { useAppState } from "lib/hooks/useAppState";
import useTranslation from "lib/hooks/useTranslation";
import React, { useEffect } from "react";
import ComingSoon from "./ComingSoon";
import Ende from "./Ende";
import Startseite from "./Startseite";

const Home = ({ language }) => {
  const { state } = useAppState();
  const { setLanguage } = useTranslation();

  useEffect(() => {
    setLanguage(language);
  }, [language, setLanguage]);

  return (
    <>
      {/*       {state.week === -99 && <ComingSoon />}
      {state.week === 99 && <Ende />}
      {state.week >= 1 && state.week <= 14 && (
        <Startseite language={language} />
      )} */}
      {/* <ComingSoon /> */}
      <Startseite language={language} />
    </>
  );
};

export default Home;
