import {
  ACTIONS as FORMACTIONS,
  useFormularState,
} from "lib/hooks/useFormularState";
import useTranslation from "lib/hooks/useTranslation";
import React, { useEffect, useState, useRef } from "react";

const Frage_3 = ({ setFehler }) => {
  const { t } = useTranslation();
  const { formDispatch, formState } = useFormularState();

  const onChange = (event) => {
    setFehler("");
    console.log(event.target.checked);
    formDispatch({
      type: FORMACTIONS.SET_DATA,
      payload: {
        key: event.target.name,
        value: event.target.checked,
      },
    });
  };

  return (
    <>
      <div className="px-8 text-lg leading-7 font-extrabold italic md:text-[26px] md:leading-[36px]">
        {t("frage_3")}
      </div>
      <div className="px-8 text-base md:text-xl  mt-2">
        {t("frage_3_auswahl")}
      </div>
      <div className="px-8">
        {formState.antworten_3.map((item) => {
          return (
            <div className="flex items-center mt-6">
              <input
                checked={formState[item.kennung]}
                name={item.kennung}
                onChange={onChange}
                id={item.nummer}
                type="checkbox"
                className="text-black bg-black w-6 h-6 border border-white rounded checked:border-white checked:hover:border-white checked:hover:bg-black focus:border-transparent focus:ring-0 "
              />
              <label htmlFor={item.nummer} className="ml-4 md:text-xl">
                {t("frage_3_antwort_" + item.nummer)}
              </label>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Frage_3;
