import styled from "styled-components";

export const StyledGrid = styled.div`
  display: flex;
`;

export const StyledCol = styled.div`
  flex: 1;
`;

export const StyledGridHalf = styled.div`
  @media (min-width: ${(props) => props.theme.breakPoints.md}) {
    display: flex;
    justify-content: space-between;
  }
`;

export const StyledColHalf = styled.div`
  width: 100%;
  @media (min-width: ${(props) => props.theme.breakPoints.md}) {
    width: 48%;
  }
`;

export const StyledGridThirds = styled.div`
  @media (min-width: ${(props) => props.theme.breakPoints.md}) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  ::after {
    content: "";
    flex-basis: 32%;
  }
`;

export const StyledColThirds = styled.div`
  width: 100%;
  @media (min-width: ${(props) => props.theme.breakPoints.md}) {
    width: 32%;
  }
`;
