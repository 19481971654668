import styled from "styled-components";
import React from "react";
import hamburger from "./Hamburger.svg";
import close from "./Close.svg";
import logo from "./Logo_EEM.png";
import logo_beige from "./Logo_Michel_beige.svg";
import { ACTIONS, useAppState } from "lib/hooks/useAppState";
import useTranslation from "lib/hooks/useTranslation";
import { SCREENS } from "lib/constants";

const Container = styled.div`
  background-color: rgba(0, 0, 0, 0);
  position: ${(props) => (props.menue ? "fixed" : "absolute")};
  top: 0px;
  /* left: 0px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  z-index: 4000;
  color: white;
  width: 100%;
  max-width: 1440px;
  height: 71px;
  padding: 0 24px;
  @media (min-width: ${(props) => props.theme.breakPoints.md}) {
    top: 32px;
  }
`;

const Links = styled.div`
  display: flex;
  align-items: center;
  padding-right: 16px;
  /*   @media (min-width: 440px) {
    padding-right: 32px;
  }
  @media (min-width: 1024px) {
    padding-right: 90px;
  } */
`;
const StyledLink = styled.div`
  font-family: ${(props) => props.theme.font_text};
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  padding: 0px 6px;
  cursor: pointer;
  color: ${(props) => (props.menue ? "#fff" : "fff")};
  font-weight: ${(props) => (props.selected ? "700" : "400")};
`;

const ImgWrapper = styled.div`
  /* padding-left: 24px; */

  text-align: ${(props) => props.align};
`;

const Navigation = () => {
  /* const router = useRouter(); */
  const { state, dispatch } = useAppState();
  const { language } = useTranslation();

  const clickHandler = () => {
    dispatch({
      type: ACTIONS.SET_OVERLAYMENUVISIBLE,
      payload: !state.overlayMenuVisible,
    });
  };

  const handleClick = (sprache) => (event) => {
    console.log(window.location.search);
    const newURL =
      window.location.protocol +
      "//" +
      window.location.host +
      process.env.PUBLIC_URL +
      sprache;
    window.location.href = newURL + window.location.search;
  };

  return (
    <Container menue={state.overlayMenuVisible}>
      <div className="flex">
        {state.week < 5 && (
          <ImgWrapper align="left">
            <img
              onClick={clickHandler}
              src={state.overlayMenuVisible ? close : hamburger}
              alt=""
              style={{ display: "inline" }}
            ></img>
          </ImgWrapper>
        )}

        <Links>
          <StyledLink
            menue={state.overlayMenuVisible}
            selected={language === "de"}
            onClick={handleClick("/de")}
          >
            DE
          </StyledLink>

          <StyledLink menue={state.overlayMenuVisible} selected={false}>
            |
          </StyledLink>

          <StyledLink
            menue={state.overlayMenuVisible}
            selected={language === "fr"}
            onClick={handleClick("/fr")}
          >
            FR
          </StyledLink>
        </Links>
      </div>

      <div
        onClick={() => {
          dispatch({
            type: ACTIONS.SET_SCREEN,
            payload: SCREENS.STARTSEITE,
          });
        }}
      >
        <img className="w-12 heart" src={logo} alt="" />
      </div>
    </Container>
  );
};

export default Navigation;
