const defaultBreakpoints = {
  sm: "600px",
  md: "768px",
  lg: "992px",
  xl: "1224px",
  xxl: "1440px",
};

const color = {
  text_dark: "black",
};

export const theme_puretaste = {
  breakPoints: defaultBreakpoints,
  font_text: "'futura-pt', sans-serif",
  font_titel: "'futura-pt', sans-serif",
  color: color,
};
