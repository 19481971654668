import React from "react";
import styled from "styled-components";

const Label = styled.label`
  display: block;
  text-transform: uppercase;
  color: white;
  font-style: italic;
  font-weight: 800;
  font-family: futura-pt, sans-serif;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;

  @media (min-width: 750px) {
    font-size: 26px;
  }
`;

const Input = styled.input`
  font-family: "Assistant";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  padding: 8px 16px;
  background-color: ${(props) => (props.error ? "#ff9595" : "#eee")};
  color: #222;
  width: 100%;

  &:focus {
    outline: none;
    border-bottom-width: 2px;
  }
`;

export const TextInput = ({
  error,
  name,
  value,
  onChange,
  onBlur,
  label,
  placeholder,
  type = "text",
  onFocus,
}) => {
  return (
    <div>
      <Label>{label}</Label>
      <Input
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        type={type}
        placeholder={placeholder}
        error={error}
        onFocus={onFocus}
      />
    </div>
  );
};
