import { ACTIONS, useAppState } from "lib/hooks/useAppState";
import React, { useEffect } from "react";
import styled from "styled-components";

const Wochen = () => {
  const { state, dispatch } = useAppState();

  const handleChange = (e) => {
    dispatch({
      type: ACTIONS.SET_WEEK,
      payload: e.target.value,
    });
  };

  useEffect(() => {
    if (state.week === -99) {
      dispatch({
        type: ACTIONS.SET_WEEK,
        payload: 1,
      });
    }
  }, [dispatch, state.week]);

  return (
    <div>
      <Test>
        {/* <select onChange={(e) => handleChange(e)} name="wochen">
          <option value="1">Woche 1</option>
          <option value="2">Woche 2</option>
          <option value="3">Woche 3</option>
          <option value="4">Woche 4</option>
          <option value="5">Woche 5</option>
          <option value="6">Woche 6</option>
          <option value="7">Woche 7</option>
          <option value="8">Woche 8</option>
          <option value="9">Woche 9</option>
          <option value="10">Woche 10</option>
          <option value="11">Woche 11</option>
          <option value="12">Woche 12</option>
          <option value="13">Woche 13</option>
          <option value="14">Woche 14</option>
        </select> */}
        <div>
          <b>v 0.99</b>
        </div>
      </Test>
    </div>
  );
};

const Test = styled.div`
  position: absolute;
`;

export default Wochen;
