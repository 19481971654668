import React from "react";
import useTranslation from "lib/hooks/useTranslation";
import { YSpacer } from "lib/responsive-spacer";
import styled from "styled-components";
import { StyledH1, StyledH2, StyledParagraph, StyledSmall } from "styles/typo";
import zug from "img/Zug.png";
import zug_desktop from "img/Zug_desktop.png";
import logo from "img/Logo_railtour_cmyk 1.png";
import { ACTIONS, useAppState } from "lib/hooks/useAppState";
import { SCREENS } from "lib/constants";
import { useMediaQuery } from "react-responsive";

const Railtour = () => {
  const { t } = useTranslation();
  const { dispatch } = useAppState();
  const isDesktop = useMediaQuery({ minWidth: 768 });

  const handleBack = () => {
    dispatch({ type: ACTIONS.SET_SCREEN, payload: SCREENS.STARTSEITE });
    dispatch({
      type: ACTIONS.SET_CLICKEDMENUITEM,
      payload: t("railtour_back"),
    });
  };

  return (
    <>
      <StyledTeaser>
        <Container>
          <>
            <StyledImage
              src={`/images/skichallenge/skichallenge.jpg`}
              alt=""
            ></StyledImage>
            <StyledContainer>
              <YSpacer mb={32}></YSpacer>
              <StyledH2 center>{t("skichallenge_titel")}</StyledH2>
              <YSpacer mb={16}></YSpacer>
              <StyledParagraph center>{t("skichallenge_text")}</StyledParagraph>
              <YSpacer mb={32}></YSpacer>
              <Button
                src={"/images/skichallenge/ButtonAppStore.png"}
                alt=""
              ></Button>
              <YSpacer mb={24}></YSpacer>
              <Button
                src={"/images/skichallenge/ButtonGooglePlay.png"}
                alt=""
              ></Button>
              <YSpacer mb={48}></YSpacer>
            </StyledContainer>
          </>
          <Close onClick={handleBack}>
            <Back>
              <svg
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 1L1 7L7 13"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Back>
            <StyledSmall>{t("railtour_back")}</StyledSmall>
          </Close>
          <YSpacer mb={48}></YSpacer>
        </Container>
      </StyledTeaser>
    </>
  );
};

const Button = styled.img`
  max-height: 40px;
  display: block;
  margin: 0 auto;
`;

const StyledContainer = styled.div`
  position: relative;
  max-width: 350px;
  margin: 0 auto;
  background-color: white;
  padding: 0 16px;
`;

const StyledImage = styled.img`
  width: 100%;
  max-width: 440px;
  display: block;
`;

const StyledTeaser = styled.div``;

const Container = styled.div`
  background-color: #fff;
  padding: 0 30px;
  padding-top: 56px;
  margin: 0 auto;
  width: 100%;
  @media (min-width: ${(props) => props.theme.breakPoints.md}) {
    max-width: 1024px;
    padding: 0;
  }
`;

const Back = styled.div`
  margin-right: 8px;
`;

const Close = styled.div`
  cursor: pointer;
  display: flex;
`;

export default Railtour;
