import useTranslation from "lib/hooks/useTranslation";
import React from "react";
import styled from "styled-components";

const Stoerer = () => {
  const { language } = useTranslation();
  const l = language.toUpperCase();

  return (
    <div className="flex justify-center bg-white py-6 md:py-12">
      <StyledImage
        src={`/images/stoerer/Mobile_Packshots_${l}.png`}
        alt=""
      ></StyledImage>
    </div>
  );
};

const StyledImage = styled.img`
  width: 100%;
  max-width: 540px;
  display: block;
`;

export default Stoerer;
