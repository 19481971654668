import useTranslation from "lib/hooks/useTranslation";
import React from "react";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components";
import { StyledContainer } from "styles/uielements";

const ComingSoon = () => {
  const { language, setLanguage } = useTranslation();

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 768px)",
  });

  return (
    <StyledContainer>
      <Links>
        <StyledLink
          onClick={() => {
            setLanguage("de");
          }}
          selected={language === "de"}
        >
          DE
        </StyledLink>

        <StyledLink selected={false}>|</StyledLink>

        <StyledLink
          onClick={() => {
            setLanguage("fr");
          }}
          selected={language === "fr"}
        >
          FR
        </StyledLink>
      </Links>
      <Bild
        src={
          isDesktopOrLaptop
            ? `${process.env.PUBLIC_URL}/images/cs/desktop_ende_${language}.jpg`
            : `${process.env.PUBLIC_URL}/images/cs/mobile_ende_${language}.jpg`
        }
      ></Bild>
    </StyledContainer>
  );
};

const Bild = styled.img`
  max-width: 100%;
  display: block;
  margin: 0 auto;
  @media (min-width: 768px) {
    max-width: 100%;
  }
`;

const Links = styled.div`
  position: absolute;
  width: 33%;
  display: flex;
  align-items: center;
  padding-right: 16px;
  padding-top: 24px;
  padding-left: 16px;
  @media (min-width: 440px) {
    padding-right: 32px;
  }
  @media (min-width: 1024px) {
    padding-right: 90px;
  }
`;
const StyledLink = styled.div`
  font-family: ${(props) => props.theme.font_text};
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  padding: 0px 6px;
  cursor: pointer;
  color: ${(props) => (props.menue ? "#F3E3CA" : "white")};
  font-weight: ${(props) => (props.selected ? "700" : "400")};
`;

export default ComingSoon;
