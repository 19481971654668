import React from "react";
import useTranslation from "lib/hooks/useTranslation";
import { YSpacer } from "lib/responsive-spacer";
import styled from "styled-components";
import { StyledH2, StyledH3 } from "styles/typo";
import { PREISE } from "lib/constants";
import { StyledColThirds, StyledGridThirds } from "styles/grid";

const Sofortpreise = () => {
  const { t } = useTranslation();

  const items = [
    {
      bild: PREISE.OCHSNER,
      anzahl: "24x",
      text: t("sofortpreise_ochsner"),
    },
    {
      bild: PREISE.YOGAMATTE,
      anzahl: "8x",
      text: t("sofortpreise_yogamatte"),
    },
    {
      bild: PREISE.EXERBELL,
      anzahl: "16x",
      text: t("sofortpreise_exerbell"),
    },
    {
      bild: PREISE.FLASCHE,
      anzahl: "40x",
      text: t("sofortpreise_flasche"),
    },
    {
      bild: PREISE.MIKROFASERTUCH,
      anzahl: "40x",
      text: t("sofortpreise_mikrofasertuch"),
    },
    {
      bild: PREISE.TASCHE,
      anzahl: "64x",
      text: t("sofortpreise_tasche"),
    },
  ];

  return (
    <div>
      <div>
        <div className="text-3xl font-extrabold italic mt-20 px-8 mb-14 uppercase  md:text-[48px] md:leading-[56px]">
          {t("sofortpreise_titel")}
          <div className="w-[87px] h-[4px] bg-[#FFED00] mt-1"></div>
        </div>
        <div className="md:flex md:flex-wrap">
          {items.map((item, index) => {
            return (
              <div className="mb-20 md:w-4/12" key={index}>
                <img
                  className="leading-none block align-middle px-8 md:px-0 md:pl-8 md:max-w-[304px]"
                  src={`/images/sofortpreise_ohne/${item.bild}.jpg`}
                  alt=""
                ></img>
                <div className="-mt-6 md:-mt-3">
                  <div className="bg-[#FFED00] text-black inline text-3xl font-extrabold italic px-4 py-2 md:text-[26px] md:leading-[26px] md:px-5 ml-12">
                    {item.anzahl}
                  </div>
                </div>
                <div className="text-3xl md:text-[26px] md:leading-[36px] font-extrabold italic mt-8 px-8 md:px-10">
                  {item.text}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const StyledImageContainer = styled.div`
  position: relative;
  max-width: 350px;
  margin: 0 auto;
  padding-top: 32px;
  background-color: white;
`;

const StyledImage = styled.img`
  width: 100%;
  max-width: 440px;
  display: block;
`;

const StyledTeaser = styled.div`
  background-color: #f1f1f1;
`;

const TitelContainer = styled.div`
  display: inline-block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
`;

const Container = styled.div`
  background-color: #f1f1f1;
  padding: 0 30px;
  margin: 0 auto;
  width: 100%;
  @media (min-width: ${(props) => props.theme.breakPoints.md}) {
    max-width: 1024px;
    padding: 0;
  }
`;

export default Sofortpreise;
