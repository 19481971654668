// Applikations State
import { PRODUKTE, SCREENS } from "lib/constants";
import React, { useReducer, useContext, useEffect } from "react";
import useApiURL from "./useApiURL";

export const ACTIONS = {
  SET_FORMULAR_VISIBLE: "SET_FORMULAR_VISIBLE",
  SET_WEEK: "SET_WEEK",
  SET_SCREEN: "SET_SCREEN",
  SET_GEWONNEN: "SET_GEWONNEN",
  SET_VERLOREN: "SET_VERLOREN",
  SET_PRODUKT: "SET_PRODUKT",
  SET_PRODUKT_MIT_SIZE: "SET_PRODUKT_MIT_SIZE",
  START_ANIMATION: "START_ANIMATION",
  SET_IS_SOFORTPREIS: "SET_IS_SOFORTPREIS",
  SET_SOFORTPREIS_INFO: "SET_SOFORTPREIS_INFO",
  SET_OVERLAYMENUVISIBLE: "SET_OVERLAYMENUVISIBLE",
  SET_CLICKEDMENUITEM: "SET_CLICKEDMENUITEM",
  RESET: "RESET",
  STOP_ANIMATION: "STOP_ANIMATION",
  SET_SHOW_CLIP_INTRO: "SET_SHOW_CLIP_INTRO",
  SET_SHOW_CLIP_TEILNAHME: "SET_SHOW_CLIP_TEILNAHME",
  SET_SHOW_FORM: "SET_SHOW_FORM",
  SET_TOKEN: "SET_TOKEN",
  SET_AKTIVE_FRAGE: "SET_AKTIVE_FRAGE",
};

const INITIAL_STATE = {
  zuEnde: true, // Wenn die Kampagne zu Ende ist
  aktive_frage: 1,
  formularVisible: false,
  screen: SCREENS.STARTSEITE,
  week: null,
  gewonnen: false,
  verloren: false,
  produkt_url: PRODUKTE.CAP,
  produkt_mit_size: "",
  startAnimation: false,
  isSofortpreis: false,
  sofortpreis_typ: null,
  sofortpreis_id: null,
  overlayMenuVisible: false,
  clickedMenuItem: "",
  showIntroClip: true,
  showTeilnahmeClip: false,
  showForm: false,
  token: null,
};

const reducer = function (state, action) {
  //console.log(action);
  switch (action.type) {
    case ACTIONS.SET_AKTIVE_FRAGE:
      return {
        ...state,
        aktive_frage: action.payload,
      };
    case ACTIONS.SET_SHOW_FORM:
      return {
        ...state,
        showForm: action.payload,
      };
    case ACTIONS.SET_SHOW_CLIP_INTRO:
      return {
        ...state,
        showIntroClip: action.payload,
      };
    case ACTIONS.SET_SHOW_CLIP_TEILNAHME:
      return {
        ...state,
        showTeilnahmeClip: action.payload,
      };
    case ACTIONS.RESET:
      return {
        ...state,
        gewonnen: false,
        verloren: false,
        startAnimation: false,
        isSofortpreis: false,
        sofortpreis_typ: null,
        sofortpreis_id: null,
        showForm: false,
      };
    case ACTIONS.SET_CLICKEDMENUITEM:
      return {
        ...state,
        clickedMenuItem: action.payload,
      };
    case ACTIONS.SET_OVERLAYMENUVISIBLE:
      return {
        ...state,
        overlayMenuVisible: action.payload,
      };
    case ACTIONS.SET_SOFORTPREIS_INFO:
      return {
        ...state,
        sofortpreis_typ: action.payload.typ,
        sofortpreis_id: action.payload.preis_id,
      };
    case ACTIONS.SET_IS_SOFORTPREIS:
      return {
        ...state,
        isSofortpreis: action.payload,
      };
    case ACTIONS.START_ANIMATION:
      return {
        ...state,
        startAnimation: true,
      };
    case ACTIONS.STOP_ANIMATION:
      return {
        ...state,
        startAnimation: false,
      };
    case ACTIONS.SET_PRODUKT:
      return {
        ...state,
        produkt_url: action.payload,
      };
    case ACTIONS.SET_PRODUKT_MIT_SIZE:
      return {
        ...state,
        produkt_mit_size: action.payload,
      };
    case ACTIONS.SET_GEWONNEN:
      return {
        ...state,
        gewonnen: true,
      };
    case ACTIONS.SET_VERLOREN:
      return {
        ...state,
        verloren: true,
      };
    case ACTIONS.SET_SCREEN:
      return {
        ...state,
        screen: action.payload,
      };
    case ACTIONS.SET_FORMULAR_VISIBLE:
      return {
        ...state,
        formularVisible: action.payload,
      };
    case ACTIONS.SET_WEEK:
      return {
        ...state,
        week: action.payload,
      };
    case ACTIONS.SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

const AppContext = React.createContext();

export const AppStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const { base_url } = useApiURL();

  useEffect(() => {
        const fetchWeek = async () => {
      const response = await fetch(base_url + "getweek.php", {
        method: "GET",
        headers: {
          accept: "application/JSON",
        },
      });
      const data = await response.json();
      dispatch({
        type: ACTIONS.SET_WEEK,
        payload: data.payload,
      });
      console.log(data.payload);
    };

    fetchWeek();
    dispatch({
      type: ACTIONS.SET_WEEK,
      payload: 10,
    });
  }, [base_url]);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppState = () => {
  return useContext(AppContext);
};
